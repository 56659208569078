import { prop, required } from "@messaia/cdk";

export class MailServer {
	@required()
	host: string;

	@required()
	port: number;

	@required()
	userName: string;

	@prop()
	password: string;

	@prop()
	useSSL: string;

	@prop()
	passwordClear: string;

	/**
	 * constructor
	 * @param init 
	 */
	public constructor(init?: Partial<MailServer>) {
		Object.assign(this, init);
	}
}