export class Contact {
	salutation: number;
	title: string;
	firstName: string;
	lastName: string;
	name: string;
	phonePrefix: string;
	phoneNumber: string;
	faxPrefix: string;
	faxNumber: string;
	email: string;
	notice: string;

	/**
	 * constructor
	 * @param init 
	 */
	public constructor(init?: Partial<Contact>) {
		Object.assign(this, init);
	}
}