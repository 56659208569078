import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, AutoLoginComponent, ForbiddenComponent, LoggedoutComponent, RoleGuard, UnauthorizedComponent } from '@app/core';
import { Roles } from '@app/customers-base';
import { MenuResolve } from '@messaia/cdk';
import { environment } from '../environments/environment';
import { MainComponent } from './main/main.component';

const frontendRoles = [Roles.Landlord, Roles.Owner, Roles.Tenant];

const routes: Routes = [
    { path: '', redirectTo: environment.postLoginRoute, pathMatch: 'full' },
    {
        path: '', component: MainComponent, resolve: { menu: MenuResolve }, data: { scope: 'site', includes: ['items.roles', 'items.items.roles'], enabled: true }, children: [
            { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
            { path: 'email', loadChildren: () => import('./email/email.module').then(m => m.EmailModule) },
            { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule) },
            { path: 'contracts', loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule) },
            { path: 'bills', loadChildren: () => import('./bills/bills.module').then(m => m.BillsModule) },
            { path: 'issues', loadChildren: () => import('./issues/issues.module').then(m => m.IssuesModule) }
        ]
    },
    { path: 'login', component: AutoLoginComponent },
    { path: 'loggedout', component: LoggedoutComponent },
    { path: 'forbidden', component: ForbiddenComponent },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: '**', redirectTo: environment.postLoginRoute, pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard, RoleGuard, MenuResolve]
})
export class AppRoutingModule { }