export enum SyncOperation {
    Create = 1,
    Update,
    Delete,
    Cancel,

    CreatePdf = 10,

    GetStatus = 15,
    UpdateStatus,

    SendMail = 25,
    GetExternalCustomerId,
    UpdateCustomerBankAccount,
    CreateAccount,
    SendTwoFactorAuthMigration,
    SendRegistrationConfirmation,
    SendResetPasswordRequest,
    SendOrderConfirmation,
    CreateConsultationProtocol,
    SendConsultationProtocol,
    CreateProviderChangeDocument,
    SendProviderChangeDocument,
    CreateContractActivationConfirmation,
    SendContractActivationConfirmation,
    SendNewOrderAdminNotification,
    SendTariffDowngradeReminder,
    UpdateCustomerPaymentMethod,

    RevokeCancellation = 100,
    Terminate,
    RevokeTermination,

    ChangeTariff = 150,
    ChangeProductOld,
    ChangeProductNew,

    GetOrderStatus = 200,
    GetTariffStatus,
    GetProvisionStatus,
    GetVoiceTariffDetails,
    GetVoiceOperationStatus,
    GetProcesses,
    DetermineWbciDetails,
    GetTariffWbciDetails,
    SendPortationConfirmation,
    SendAssignedNumbersConfirmation,
    GetPortationTicketStatus,

    ReduceSpeed = 250,
    RevokeSpeedReduction,

    ImportNumber = 300,
    ImportNumberDodumentUpload,
    ExportPhoneNumber,
    ReturnPhoneNumber,
    OrderPhoneNumber,

    OrderPhoneNumberBlock = 320,

    NumberBlockPorting = 350,
    NumberBlockPortingDodumentUpload,

    ChangePreferredDate = 400,
    UpdateItemizedBillType,
    ChangeBillingDate,

    CreateInternalBills = 600,
    DownloadBills,
    CreateItemizedBillPdf,
    CreateCredentialsPdf,
    DownloadCredentialsPdf
}