<mat-accordion *ngIf="item" [multi]="multiple">
    <!-- #region Line address -->
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title i18n>Line options</mat-panel-title>
        </mat-expansion-panel-header>

        <!-- #region Phone prefix & number -->
        <app-phone-form [item]="item" modelPrefix="currentLine" [readonly]="readonly" [required]="false"></app-phone-form>
        <!-- #endregion -->

        <div layout-gt-sm="row" layout="column">
            <!-- #region TAE Location -->
            <mat-form-field flex layout-margin>
                <mat-label i18n>TAE location</mat-label>
                <input matInput name="taeLocation" [(ngModel)]="item.taeLocation" #tAELocation="ngModel" [readonly]="readonly" placeholder="TAE location" i18n-placeholder>
                <mat-error *ngIf="tAELocation.errors?.required"><span i18n>TAE location</span><span i18n> is required</span></mat-error>
            </mat-form-field>
            <!-- #endregion -->

            <!-- #region Itemized bill -->
            <mat-form-field flex layout-margin>
                <mat-label i18n>Itemized bill</mat-label>
                <vd-select [(ngModel)]="item.itemizedBillType" [enum]="ItemizedBillType" name="itemizedBillType" #itemizedBillType="ngModel" [disabled]="readonly" placeholder="Itemized bill" i18n-placeholder required flex></vd-select>
                <mat-error *ngIf="itemizedBillType.errors?.required"><span i18n>Itemized bill</span><span i18n> is required</span></mat-error>
            </mat-form-field>
            <!-- #endregion -->

            <!-- #region Preferred date -->
            <mat-form-field flex layout-margin *ngIf="false">
                <mat-label i18n>Preferred date</mat-label>
                <input matInput autocomplete="off" [matDatepicker]="preferredDatePicker" name="preferredDate" [(ngModel)]="item.preferredDate" [readonly]="readonly" placeholder="Preferred date" i18n-placeholder>
                <mat-datepicker-toggle matSuffix [for]="preferredDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #preferredDatePicker></mat-datepicker>
            </mat-form-field>
            <!-- #endregion -->
        </div>
    </mat-expansion-panel>
    <!-- #endregion -->

    <!-- #region Contact / Manager -->
    <mat-expansion-panel [expanded]="multiple">
        <mat-expansion-panel-header>
            <mat-panel-title i18n>Contact / Manager</mat-panel-title>
        </mat-expansion-panel-header>
        <app-contact-form [contact]="item.activationContact" [readonly]="readonly" showName="true" showEmail="true"></app-contact-form>
    </mat-expansion-panel>
    <!-- #endregion -->

    <!-- #region Previous tenant -->
    <mat-expansion-panel [expanded]="multiple" *ngIf="item.previousTenant">
        <mat-expansion-panel-header>
            <mat-panel-title i18n>Previous tenant</mat-panel-title>
        </mat-expansion-panel-header>
        <app-contact-form [contact]="item.previousTenant" [readonly]="readonly" required="false"></app-contact-form>
    </mat-expansion-panel>
    <!-- #endregion-->
</mat-accordion>