import { required } from "@messaia/cdk";
import { PriceBase } from "./price-base";

export class TotalPrice extends PriceBase {
    @required()
    net: number = 0;

    /**
     * constructor
     * @param init 
     */
    public constructor(init?: Partial<TotalPrice>) {
        super(init);
        Object.assign(this, init);
    }
}