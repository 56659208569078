import { AuditEntity, ISyncable } from "@app/common";
import { Address, Contact, PaymentMethod, Sepa, SyncSchedule } from "@app/core";
import { Column, Display, Table } from "@messaia/cdk";
import { CustomerType } from "../enums/customer-type";
import { CustomerConnection } from "./customer-connection";

@Table({
	endpoint: 'customer',
	includes: ['Connections', 'Client']
})
export class Customer extends AuditEntity implements ISyncable {
	@Column({ hide: (data: { type: CustomerType }) => data?.type == CustomerType.Commercial })
	@Display($localize`:@@firstName:First name`)
	firstName: string;

	@Column({ hide: (data: { type: CustomerType }) => data?.type == CustomerType.Commercial })
	@Display($localize`:@@lastName:Last name`)
	lastName: string;

	@Column({ hide: (data: { type: CustomerType }) => data?.type == CustomerType.Commercial, showGtMd: true })
	@Display($localize`:@@company:Company`)
	company: string;

	@Column({ hide: (data: { type: CustomerType }) => data?.type != CustomerType.Commercial })
	@Display($localize`:@@company:Company`)
	displayName: string;

	@Column({ showGtLg: true })
	@Display($localize`:@@email:Email`)
	email: string;

	@Column({
		name: 'connections.Min(address.address1)',
		filter: 'connections.address.address1',
		content: (row: Customer) => `${row.connections[0]?.address?.address1 ?? ''}`,
		showGtXl: true
	})
	@Display($localize`:@@address1:Address 1`)
	private address1: string;

	@Column({
		name: 'connections.Min(address.address2)',
		filter: 'connections.address.address2',
		content: (row: Customer) => `${row.connections[0]?.address?.address2 ?? ''}`,
		width: 80,
		showGtXl: true
	})
	@Display($localize`:@@address2:Address 2`)
	private address2: string;

	@Column({
		name: 'connections.Min(address.address3)',
		filter: 'connections.address.address3',
		content: (row: Customer) => `${row.connections[0]?.address?.address3 ?? ''}`,
		width: 140,
		showGtXl: true
	})
	@Display($localize`:@@address3:Address 3`)
	private address3: string;

	// @Column({ name: 'client.displayName', endpoint: 'client?enabled=true&projection=id,name', maxWidth: 250, showGtXl: true })
	@Display($localize`:@@client:Client`)
	clientId: number;

	@Column({ event: 'toggle' })
	@Display($localize`:@@enabled:Enabled`)
	enabled: boolean = true;

	@Column({
		content: (row: Customer) => row.selfRegistered ? 'Online' : 'Manuell',
		options: [{ id: false, name: 'Manuell' }, { id: true, name: 'Online' }],
		showGtXl: true
	})
	@Display($localize`:@@source:Source`)
	selfRegistered: boolean;

	@Column({ filterOperator: '~', width: 100, showGtLg: true })
	@Display($localize`:@@number:Number`)
	number: Number;

	type: CustomerType;
	userName: string;
	companyEmail: string;
	password: string;
	confirmPassword: string;
	salutation: number;
	title: string;
	birthdate: Date;
	phonePrefix: string;
	phoneNumber: string;
	mobilePhoneNumber: string;
	companyPhoneNumber: string;
	fax: string;
	connections: CustomerConnection[] = [new CustomerConnection()];
	differentBillingAddress: boolean;
	billingAddress: Address = new Address();
	differentShippingAddress: boolean;
	shippingAddress: Address = new Address();
	contact: Contact;
	paymentMethod: PaymentMethod = PaymentMethod.DirectDebit;
	sepa: Sepa = new Sepa();
	termsAccepted: boolean = true;
	isCurrent: boolean;
	emailConfirmed: boolean;
	roleNames: string[];
	lockedOut: boolean;
	advertisingAgreed: boolean;
	phoneContactAgreed: boolean;
	emailContactAgreed: boolean;
	postContactAgreed: boolean;
	syncSchedules: SyncSchedule[];
	twoFactorEnabled: boolean;

	/**
	 * constructor
	 * @param init 
	 */
	public constructor(init?: Partial<Customer>) {
		super(init);
		Object.assign(this, init);
	}
}