import { AuditEntity } from "@app/common";
import { Address } from "@app/core";
import { Api, Column, Display, prop, propArray, propObject, required } from "@messaia/cdk";
import { ClientProvider } from "./client-provider";
import { ClientSetting } from "./client-setting";
import { ClientSupport } from "./client-support";
import { ReplicaServer } from "./replica-server";

@Api('client')
export class Client extends AuditEntity {
	@prop()
	id: number;

	@prop()
	number: number;

	@Column()
	@Display($localize`:@@name:Name`)
	@required()
	name: string;

	@Column({ showGtMd: true })
	@Display($localize`:@@email:Email`)
	@required()
	email: string;

	@prop()
	website: string;

	@required()
	phoneNumber: string;

	@prop()
	mobilePhoneNumber: string;

	@prop()
	fax: string;

	@propObject(Address)
	address: Address;

	@propObject(ClientSetting)
	setting: ClientSetting;

	@propObject(ClientSupport)
	support: ClientSupport;

	@propObject(ReplicaServer)
	replicaServer: ReplicaServer;

	@propArray(ClientProvider)
	clientProviders: ClientProvider[] = [];

	@Column({ event: 'toggle' })
	@Display($localize`:@@enabled:Enabled`)
	@prop()
	enabled: boolean;
}