import { Component, Input, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Salutation } from '@messaia/cdk';
import { Address } from '../../models/address';

let nextUniqueId = 0;

@Component({
	selector: 'app-address-form',
	templateUrl: './address-form.component.html',
	styleUrls: ['./address-form.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		useFactory: (controlContainer?: ControlContainer) => controlContainer,
		deps: [[new Optional(), NgForm]]
	}]
})
export class AddressFormComponent {

	/**
	 * Unique id
	 */
	public id: number = nextUniqueId++;

	/**
	 * Address object
	 */
	@Input()
	public address: Address;

	/**
	 * A Shows first and alst name
	 */
	@Input()
	public extended: boolean = false;

	/**
	 * If true, sets input as required
	 */
	@Input()
	public required: boolean = true;

	/**
	 * If true, show district
	 */
	@Input()
	public showDistrict: boolean;

	/**
	 * If true, show country
	 */
	@Input()
	public showCountry: boolean = true;

	/**
	 * If true, sets inputs as readonly
	 */
	@Input()
	public readonly: boolean = false;

	/**
	 * The type of salutation enum
	 */
	public Salutation: typeof Salutation = Salutation;
}