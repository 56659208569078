import { AuditEntity } from "@app/common";
import { Address } from "@app/core";
import { Customer, LineOption } from "@app/customers-base";
import { CustomerConnectionSyncApi } from "./customer-connection-sync-api";

export class CustomerConnection extends AuditEntity {
    externalCustomerId: string;
    customerId: number | null;
    customer: Customer;
    address: Address = new Address();
    lineOption: LineOption = new LineOption();
    syncApis: CustomerConnectionSyncApi[];
    addressEditable: boolean;

    /**
     * constructor
     * @param init 
     */
    public constructor(init?: Partial<CustomerConnection>) {
        super(init);
        Object.assign(this, init);
    }
}