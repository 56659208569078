import { Injectable } from '@angular/core';
import { AuthHelper, GenericService } from '@messaia/cdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notification } from '../models/notification';

@Injectable({ providedIn: 'root' })
export class NotificationService extends GenericService<Notification> {

	/**
	 * Broadcaster
	 */
	public broadcaster: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	/**
	 * Constructor
	 */
	constructor() {
		super('notification');
	}

	/**
	 * Count unread messages
	 */
	public countUnread(): void {
		this.count({ customerId: AuthHelper.user.id, read: false }).subscribe(x => this.broadcaster.next(x));
	}

	/**
	 * Marks all notifications as read
	 * @param id 
	 */
	public markAllAsRead(): Observable<any> {
		return this.update('', null, '/markAllAsRead');
	}
}