import { Component } from '@angular/core';
import { AuthHelper, AuthUser } from '@messaia/cdk';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
	selector: 'app-forbidden',
	templateUrl: './forbidden.component.html',
	styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent {

	/**
	 * Logged in user
	 */
	public user: AuthUser = AuthHelper.user;

	/**
	 * constructor
	 * @param oidcSecurityService 
	 */
	constructor(private oidcSecurityService: OidcSecurityService) { }

	/**
	 * Logs user out.
	 */
	public logout() {
		this.oidcSecurityService.logoff();
	}
}