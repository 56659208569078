import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthHelper, AuthUser, Menu } from '@messaia/cdk';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {

	/**
	 * Page title
	 */
	public title: string;

	/**
	 * The name of the project
	 */
	public projectName: string = environment.projectName;

	/**
	 * Copyright
	 */
	public copyright: string = environment.copyright;

	/**
	 * Page title
	 */
	public loaded: boolean;

	/**
	 * Menu items
	 */
	public menu: Array<Menu>;

	/**
	 * User menu
	 */
	public userMenu: any[];

	/**
	 * Authorized status
	 */
	public isAuthorized: boolean;

	/**
	 * Current user object
	 */
	public user: AuthUser = AuthHelper.user;

	/**
	 * onLogout?: function
	 * Event emitted when a meni logout is clicked.
	 */
	@Output() onLogout: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Constructor
	 */
	constructor(
		public oidcSecurityService: OidcSecurityService,
		private router: Router,
		private route: ActivatedRoute,
		private titleService: Title,
		private iconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
	) {
		/* Listen for router events */
		this.router.events.subscribe((event) => this.loaded = event instanceof NavigationEnd);
	}

	/**
	 * Lifecycle hook that is called after data-bound properties 
	 * of a directive are initialized.
	 */
	ngOnInit() {
		/* Load user data */
		this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
			AuthHelper.user.isAuthenticated = isAuthenticated;
			if (userData) {
				AuthHelper.user = Object.assign(userData, {
					id: userData.sub,
					isAuthenticated: isAuthenticated
				});
			}
		});

		/* Get menu items from route snapshot */
		this.menu = this.route.snapshot.data.menu;

		/* Register icons */
		this.iconRegistry.addSvgIconSetInNamespace('assets', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/global.svg'));
	}

	/**
	 * Lifecycle hook that is called after a component's view has 
	 * been fully initialized.
	 */
	ngAfterViewInit(): void {
		this.title = this.titleService.getTitle();
	}
}