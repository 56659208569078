import { prop } from "@messaia/cdk";

export class ReplicaServer {
    @prop()
    host: string;

    @prop()
    port: number;

    @prop()
    userName: string;

    @prop()
    password: string;

    @prop()
    useSSL: string;

    @prop()
    passwordClear: string;

    @prop()
    directory: string;
}