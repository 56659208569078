import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
    selector: 'app-auto-component',
    template: ''
})
export class AutoLoginComponent implements OnInit {
    /**
     * Constructor
     * @param oidcSecurityService 
     */
    constructor(private oidcSecurityService: OidcSecurityService) { }

	/**
	 * Lifecycle hook that is called after data-bound properties 
	 * of a directive are initialized.
	 */
    ngOnInit() {
        this.oidcSecurityService.authorize();
    }
}