import { Component, Input } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { ItemizedBillType } from "../../enums/itemized-bill-type";
import { LineOption } from "../../models/line-option";

@Component({
    selector: 'app-customer-line-details-form',
    templateUrl: './customer-line-details-form.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CustomerLineDetailsFormComponent {
    /**
     * item: LineOption
     */
    @Input()
    public item: LineOption;

    /**
     * multiple: Boolean
     */
    @Input()
    public multiple: boolean;

    /**
     * readonly: Boolean
     */
    @Input()
    public readonly: boolean;

    /**
     * ItemizedBill enum type
     */
    public ItemizedBillType: typeof ItemizedBillType = ItemizedBillType;
}