import { NgModule } from '@angular/core';
import { AppSetting } from '@messaia/cdk';
import { AuthModule, LogLevel, StsConfigLoader, StsConfigStaticLoader } from 'angular-auth-oidc-client';

const authFactory = (appSettings: AppSetting) => {
    return new StsConfigStaticLoader({
        authority: appSettings.authUrl,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        postLoginRoute: localStorage.getItem('routeUrl') ? localStorage.getItem('routeUrl') : appSettings.postLoginRoute,
        clientId: appSettings.authClientId,
        scope: appSettings.authScope,
        responseType: appSettings.authResponseType,
        silentRenew: true,
        useRefreshToken: true,
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/unauthorized',
        logLevel: LogLevel.None,
    });
};

@NgModule({
    imports: [
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: authFactory,
                deps: [AppSetting],
            },
        }),
    ],
    exports: [AuthModule],
})
export class AuthConfigModule { }