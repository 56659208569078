import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Cost } from '../../models/cost';

@Component({
    selector: 'app-cost-form',
    templateUrl: './cost-form.component.html',
    styleUrls: ['./cost-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CostFormComponent {

    /**
     * item: Cost
     */
    @Input()
    public item: Cost;

    /**
     * readonly
     */
    @Input()
    public readonly: boolean;

    /**
     * required
     */
    @Input()
    public required: boolean = true;

    /**
     * showMinimumTerm
     */
    @Input()
    public showMinimumTerm: boolean = true;
}