<div *ngIf="item" class="container">
	<div layout-gt-sm="row" layout="column">
		<!-- #region Salutation -->
		<mat-form-field layout-margin flex>
			<mat-label i18n>Salutation</mat-label>
			<vd-select [(ngModel)]="item.salutation" [enum]="Salutation" name="personalDataSalutation{{id}}" #salutation="ngModel" placeholder="Salutation" [disabled]="disabled || readonly" (change)="onChange('salutation')" i18n-placeholder required flex></vd-select>
			<mat-error *ngIf="salutation.errors?.required"><span i18n>Salutation</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Title -->
		<mat-form-field layout-margin flex>
			<mat-label i18n>Title</mat-label>
			<input matInput name="personalDataTitle{{id}}" [(ngModel)]="item.title" #title="ngModel" minlength="2" maxlength="30" placeholder="Title" [disabled]="disabled" [readonly]="readonly" (change)="onChange('title')" i18n-placeholder>
			<mat-error *ngIf="title.errors?.minlength"><span i18n>Title</span><span i18n="@@validation.minlength"> must be at least {{title.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Customer number -->
		<mat-form-field layout-margin *ngIf="showCustomerNumber" flex>
			<mat-label i18n>Customer number</mat-label>
			<input matInput name="customerNumber{{id}}" [(ngModel)]="item.number" readonly>
			<a *ngIf="linkCustomerNumber" mat-icon-button matSuffix (click)="navigateToCustomer(item.id, item.type)">
				<mat-icon>launch</mat-icon>
			</a>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<div layout-gt-sm="row" layout="column">
		<!-- #region First name-->
		<mat-form-field flex layout-margin>
			<mat-label i18n>First name</mat-label>
			<input matInput name="personalDataFirstName{{id}}" [(ngModel)]="item.firstName" #firstName="ngModel" minlength="2" maxlength="30" [disabled]="disabled" [readonly]="readonly" (change)="onChange('firstName')" required placeholder="First name" i18n-placeholder>
			<mat-error *ngIf="firstName.errors?.required"><span i18n>First name</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="firstName.errors?.minlength"><span i18n>First name</span><span i18n="@@validation.minlength"> must be at least {{firstName.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Last name-->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Last name</mat-label>
			<input matInput name="personalDataLastName{{id}}" [(ngModel)]="item.lastName" minlength="2" maxlength="30" #lastName="ngModel" [disabled]="disabled" [readonly]="readonly" (change)="onChange('lastName')" required placeholder="Last name" i18n-placeholder>
			<mat-error *ngIf="lastName.errors?.required"><span i18n>Last name</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="firstName.errors?.minlength"><span i18n>Last name</span><span i18n="@@validation.minlength"> must be at least {{lastName.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Birthdate -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Birthdate</mat-label>
			<input matInput [matDatepicker]="birthdatePicker" name="personalDataBirthdate{{id}}" [(ngModel)]="item.birthdate" [min]="minBirthdateDate" [max]="maxBirthdateDate" [disabled]="disabled" [readonly]="readonly" #birthdate="ngModel" required placeholder="Birthdate" i18n-placeholder>
			<mat-datepicker-toggle matSuffix [for]="birthdatePicker"></mat-datepicker-toggle>
			<mat-datepicker #birthdatePicker></mat-datepicker>
			<mat-error *ngIf="birthdate.errors?.required"><span i18n>Birthdate</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="birthdate.errors?.matDatepickerMin || birthdate.errors?.matDatepickerMax"><span i18n>Birthdate</span><span i18n> is invalid</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<div layout-gt-sm="row" layout="column">
		<!-- #region Phone number-->
		<mat-form-field flex="40" layout-margin>
			<mat-label i18n>Call number</mat-label>
			<input matInput name="personalDataPhoneNumber{{id}}" [pattern]="phoneNumberPattern" [onlyNumber]="true" [(ngModel)]="item.phoneNumber" [disabled]="disabled" [readonly]="readonly" #phoneNumber="ngModel" required placeholder="Call number" i18n-placeholder>
			<mat-error *ngIf="phoneNumber.errors?.required"><span i18n>Call number</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Mobile phone number-->
		<mat-form-field flex="40" layout-margin>
			<mat-label i18n>Additional call number</mat-label>
			<input matInput name="personalDataMobilePhoneNumber{{id}}" [pattern]="phoneNumberPattern" [onlyNumber]="true" [(ngModel)]="item.mobilePhoneNumber" #mobilePhoneNumber="ngModel" [disabled]="disabled" [readonly]="readonly" placeholder="Additional call number" i18n-placeholder>
			<mat-error *ngIf="mobilePhoneNumber.errors?.required"><span i18n>Additional call number</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<!-- #region Custom fields -->
	<ng-content></ng-content>
	<!-- #endregion -->
</div>