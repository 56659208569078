import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Notification, NotificationService } from '@app/customers-base';
import { AuthHelper, Utils } from '@messaia/cdk';
import { interval } from 'rxjs';
import { NotificationDialogComponent } from './notification-dialog.component';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {

    /**
     * Numer of unread notifications
     */
    public unreadNotificationCount: number;

    /**
     * Notification list
     */
    public notifications: Notification[];

    /**
     * Constructor
     */
    constructor(
        private service: NotificationService,
        private dialog: MatDialog,
        private router: Router
    ) { }

    /**
     * Lifecycle hook that is called after data-bound properties 
     * of a directive are initialized.
     */
    ngOnInit(): void {
        this.service.broadcaster.subscribe(x => {
            this.unreadNotificationCount = x;
            this.notifications = [];

            if (x > 0) {
                this.service.getList({
                    customerId: AuthHelper.user.id,
                    read: false
                }).subscribe(x => {
                    this.notifications = x;
                    this.notifications?.forEach(x => x.content = Utils.truncateText(x.content, 80));
                });
            }
        });

        this.service.countUnread();
        interval(30000).subscribe(_ => this.service.countUnread());
    }

    /**
     * Mark a notification as read
     */
    public close(): void {
        //this.service.markAllAsRead().subscribe(_ => this.service.countUnread());
    }

    /**
     * Open details
     * @param item 
     */
    public open(item: Notification): void {
        this.service.patch(item.id, [{ op: 'replace', path: '/read', value: true }]).subscribe(x => {
            this.service.countUnread();

            if (item.link) {
                this.router.navigate([item.link]);
            }
            else {
                this.dialog.open(NotificationDialogComponent, { width: '650px', data: item, panelClass: ['dialog-full-screen'] }).afterClosed();
            }
        });
    }
}