import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, ServiceLocator, VdBaseModule, VdChipsComponent, VdDialogsModule, VdHttpModule, VdLayoutModule, VdMediaModule, VdMenuModule, VdSelectComponent } from '@messaia/cdk';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthConfigModule } from './auth-config.module';
import { AutoLoginComponent } from './components/auto-login/auto-login.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoggedoutComponent } from './components/loggedout/loggedout.component';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { RefreshComponent } from './components/refresh/refresh.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { EditorMatFormControlDirective } from './directives/editor-mat-form-control.directive';
import { AuthInterceptor } from './interceptors/auth.interceptor';

@NgModule({
    exports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
    ]
})
export class AngularModule { }

@NgModule({
    exports: [
        MatCardModule,
        MatSnackBarModule,
        MatIconModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatFormFieldModule,
        //TODO move to vd-sdk
        MatTableModule,
        MatSortModule
    ]
})
export class MaterialModule { }

@NgModule({
    exports: [
        VdLayoutModule,
        VdMediaModule,
        VdMenuModule,
        VdDialogsModule,
        VdHttpModule,
        VdBaseModule
    ]
})
export class VdModule { }

const SHARED_DECLARATIONS = [
    AutoLoginComponent,
    LoginCallbackComponent,
    ForbiddenComponent,
    UnauthorizedComponent,
    LoggedoutComponent,
    RefreshComponent,
    RedirectComponent,
    LoadingScreenComponent
]

@NgModule({
    declarations: [SHARED_DECLARATIONS],
    imports: [
        AngularModule,
        MaterialModule,
        VdModule,
        AuthConfigModule
    ],
    exports: [
        AngularModule,
        MaterialModule,
        VdModule,
        SHARED_DECLARATIONS
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: LOCALE_ID, useValue: 'de' },
        RxFormBuilder,
        OidcSecurityService,
    ],
})
export class CoreModule {

    /**
     * Constructor
     * @param injector 
     * @param oidcSecurityService
     */
    constructor(private injector: Injector) {
        ServiceLocator.injector = this.injector;

        /* Configure reactive form */
        ReactiveFormConfig.set({
            validationMessage: {
                required: $localize`:@@validation.field-required:This field is required.`,
                notEmpty: $localize`:@@validation.field-required:This field is required.`,
                alpha: $localize`:@@validation.alpha-only:Only alphabets are allowed.`,
                password: {
                    upperCase: $localize`:@@validation.password-uppercase:The password must include at least one uppercase letter.`,
                    lowerCase: $localize`:@@validation.password-lowercase:The password must include at least one lowercase letter.`,
                    digit: $localize`:@@validation.password-digit:The password must include at least one digit.`,
                    specialCharacter: $localize`:@@validation.password-special-character:The password must include at least one special character.`,
                    minLength: $localize`:@@validation.password-min-length:Minimum character length should be 6.`,
                    maxLength: $localize`:@@validation.password-max-length:Maximum character length allowed is 15.`,
                    password: $localize`:@@validation.password-invalid:Invalid password`
                },
                min: $localize`:@@validation.min-value:The value must be at least {{0}}.`,
                choice: $localize`:@@validation.field-required:This field is required.`
            },
            reactiveForm: {
                errorMessageBindingStrategy: ErrorMessageBindingStrategy.OnDirtyOrTouched
            }
        });
    }
}

//@TODO workaround
/**
 * Add required asterisk to MatInput, VdSelectComponent, ...
 */
[MatInput.prototype, MatSelect.prototype, VdSelectComponent.prototype, VdChipsComponent.prototype, EditorMatFormControlDirective.prototype].forEach(x => {
    Object.defineProperty(x, 'required', {
        get: function (): boolean {
            if (this._required) { return this._required; }

            /* The required attribute is set when the control return an error from validation with an empty value */
            if (this.ngControl && this.ngControl.control && this.ngControl.control.validator) {
                const emptyValueControl = Object.assign({}, this.ngControl.control);
                (emptyValueControl as any).value = null;
                return 'required' in (this.ngControl.control.validator(emptyValueControl) || {});
            }

            return false;
        },
        set: function (value: boolean) {
            this._required = coerceBooleanProperty(value);
        }
    });
});