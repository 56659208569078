<div *ngIf="address">
	<div layout-gt-sm="row" layout="column" *ngIf="extended">
		<!-- #region Salutation -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Salutation</mat-label>
			<vd-select [(ngModel)]="address.salutation" [disabled]="readonly" [enum]="Salutation" name="salutation{{id}}" #salutation="ngModel" placeholder="Salutation" i18n-placeholder required flex></vd-select>
			<mat-error *ngIf="salutation.errors?.required"><span i18n>Salutation</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Title -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Title</mat-label>
			<input matInput name="title" [(ngModel)]="address.title" #title="ngModel" minlength="2" maxlength="30" placeholder="Title" [readonly]="readonly" [readonly]="readonly" i18n-placeholder>
			<mat-error *ngIf="title.errors?.minlength"><span i18n>Title</span><span i18n="@@validation.minlength"> must be at least {{title.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<!-- #region First & last name -->
	<div layout-gt-sm="row" layout="column" *ngIf="extended">
		<!-- #region First name -->
		<mat-form-field flex="50" layout-margin>
			<mat-label i18n>First name</mat-label>
			<input matInput name="firstName{{id}}" minlength="2" maxlength="30" [readonly]="readonly" [(ngModel)]="address.firstName" [required]="required" #firstName="ngModel" placeholder="First name" i18n-placeholder>
			<mat-error *ngIf="firstName.errors?.required"><span i18n>First name</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="firstName.errors?.minlength"><span i18n>First name</span><span i18n="@@validation.minlength"> must be at least {{firstName.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Last name -->
		<mat-form-field flex="50" layout-margin>
			<mat-label i18n>Last name</mat-label>
			<input matInput name="lastName{{id}}" minlength="2" maxlength="30" [readonly]="readonly" [(ngModel)]="address.lastName" [required]="required" #lastName="ngModel" placeholder="Last name" i18n-placeholder>
			<mat-error *ngIf="lastName.errors?.required"><span i18n>Last name</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="lastName.errors?.minlength"><span i18n>Last name</span><span i18n="@@validation.minlength"> must be at least {{lastName.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>
	<!-- #endregion -->

	<!-- #region Address 1 & 2 -->
	<div layout-gt-sm="row" layout="column">
		<!-- #region Address 1-->
		<mat-form-field flex="40" layout-margin>
			<mat-label i18n>Address 1</mat-label>
			<input matInput name="address1{{id}}" minlength="2" maxlength="30" [readonly]="readonly" [(ngModel)]="address.address1" #address1="ngModel" [required]="required" placeholder="Address 1" i18n-placeholder>
			<mat-error *ngIf="address1.errors?.required"><span i18n>Address 1</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="address1.errors?.minlength"><span i18n>Address 1</span><span i18n="@@validation.minlength"> must be at least {{address1.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Address 2 -->
		<mat-form-field flex="30" layout-margin>
			<mat-label i18n>Address 2</mat-label>
			<input matInput name="address2{{id}}" minlength="1" maxlength="15" [readonly]="readonly" [(ngModel)]="address.address2" #address2="ngModel" [required]="required" placeholder="Address 2" i18n-placeholder>
			<mat-error *ngIf="address2.errors?.required"><span i18n>Address 2</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="address2.errors?.minlength"><span i18n>Address 2</span><span i18n="@@validation.minlength"> must be at least {{address2.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Address 3 -->
		<mat-form-field flex="30" layout-margin>
			<mat-label i18n>Address 3</mat-label>
			<input matInput name="address3{{id}}" minlength="1" maxlength="30" [readonly]="readonly" [(ngModel)]="address.address3" #address3="ngModel" placeholder="Address 3" i18n-placeholder>
			<mat-error *ngIf="address3.errors?.required"><span i18n>Address 3</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="address3.errors?.minlength"><span i18n>Address 3</span><span i18n="@@validation.minlength"> must be at least {{address3.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>
	<!-- #endregion -->

	<!-- #region Postal code & city -->
	<div layout-gt-sm="row" layout="column">
		<!-- #region Postal code-->
		<mat-form-field flex="20" layout-margin>
			<mat-label i18n>Postal code</mat-label>
			<input matInput name="postalCode{{id}}" minlength="5" maxlength="5" [readonly]="readonly" [(ngModel)]="address.postalCode" #postalCode="ngModel" [required]="required" placeholder="Postal code" i18n-placeholder>
			<mat-error *ngIf="postalCode.errors?.required"><span i18n>Postal code</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="postalCode.errors?.minlength"><span i18n>Postal code</span><span i18n="@@validation.minlength"> must be at least {{postalCode.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region City-->
		<mat-form-field flex="40" layout-margin>
			<mat-label i18n>City</mat-label>
			<input matInput name="city{{id}}" minlength="3" maxlength="60" [readonly]="readonly" [(ngModel)]="address.city" #city="ngModel" [required]="required" placeholder="City" i18n-placeholder>
			<mat-error *ngIf="city.errors?.required"><span i18n>City</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="city.errors?.minlength"><span i18n>City</span><span i18n="@@validation.minlength"> must be at least {{city.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region District-->
		<mat-form-field flex="40" layout-margin *ngIf="showDistrict">
			<mat-label i18n>District</mat-label>
			<input matInput name="district{{id}}" minlength="3" maxlength="60" [readonly]="readonly" [(ngModel)]="address.district" #district="ngModel" placeholder="District" i18n-placeholder>
			<mat-error *ngIf="district.errors?.required"><span i18n>District</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="district.errors?.minlength"><span i18n>District</span><span i18n="@@validation.minlength"> must be at least {{district.errors?.minlength?.requiredLength}} characters long</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>
	<!-- #endregion -->

	<!-- #region Country -->
	<div layout-gt-sm="row" layout="column" *ngIf="showCountry">
		<mat-form-field flex layout-margin>
			<mat-label i18n>Country</mat-label>
			<mat-select [(ngModel)]="address.country" [disabled]="readonly" name="country{{id}}" #country="ngModel" placeholder="Country" i18n-placeholder [required]="required" flex>
				<mat-option value="DE">
					<span i18n>DE</span>
				</mat-option>
			</mat-select>
			<mat-error *ngIf="country.errors?.required"><span i18n>Country</span><span i18n> is required</span></mat-error>
		</mat-form-field>
	</div>
	<!-- #endregion -->

	<!-- #region Custom fields -->
	<ng-content></ng-content>
	<!-- #endregion -->
</div>