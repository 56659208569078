<div *ngIf="item">
	<!-- #region Account holder -->
	<div layout-gt-sm="row" layout="column">
		<mat-form-field flex="50" layout-margin>
			<mat-label i18n>Account holder</mat-label>
			<input matInput name="accountHolder" [(ngModel)]="item.accountHolder" #accountHolder="ngModel" [disabled]="disabled" [readonly]="readonly" required placeholder="Account holder" i18n-placeholder>
			<mat-error *ngIf="accountHolder.errors?.required"><span i18n>Account holder</span><span i18n> is required</span></mat-error>
		</mat-form-field>
	</div>
	<!-- #endregion -->

	<!-- #region Address form -->
	<app-address-form [address]="item.address"></app-address-form>
	<!-- #endregion -->

	<div layout-gt-sm="row" layout="column">
		<!-- #region Account number -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Account number</mat-label>
			<input matInput name="accountNumber" [(ngModel)]="accountData.accountNumber" [ngModelOptions]="{ updateOn: 'blur' }" accountData #accountNumber="ngModel" [readonly]="readonly" placeholder="Account number" i18n-placeholder>
			<button type="button" mat-icon-button matSuffix>
				<mat-icon>done</mat-icon>
			</button>
			<mat-hint>IBAN unbekannt? Geben Sie hier die Kontonummer...</mat-hint>
			<mat-error *ngIf="accountNumber.errors?.invalidAccountData">{{accountNumber.errors?.invalidAccountData}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Bank code -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Bank code</mat-label>
			<input matInput name="bankCode" [(ngModel)]="accountData.bankCode" [ngModelOptions]="{ updateOn: 'blur' }" accountData #bankCode="ngModel" [readonly]="readonly" placeholder="Bank code" i18n-placeholder>
			<button type="button" mat-icon-button matSuffix>
				<mat-icon>done</mat-icon>
			</button>
			<mat-hint>und hier die BLZ ein.</mat-hint>
			<mat-error *ngIf="bankCode.errors?.invalidAccountData">{{bankCode.errors?.invalidAccountData}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<div layout-gt-sm="row" layout="column">
		<!-- #region IBAN -->
		<mat-form-field flex="70" layout-margin>
			<mat-label i18n>IBAN</mat-label>
			<input matInput name="iban" [(ngModel)]="item.iban" #iban="ngModel" [ngModelOptions]="{ updateOn: 'blur' }" (blur)="sanitizeIban()" iban required placeholder="IBAN" [disabled]="disabled" [readonly]="readonly" class="uppercase" i18n-placeholder>
			<mat-error *ngIf="iban.errors?.required"><span i18n>IBAN</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="iban.errors?.invalidIban"><span i18n>IBAN</span><span i18n> is invalid</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region BIC -->
		<mat-form-field flex="30" layout-margin>
			<mat-label i18n>BIC</mat-label>
			<input matInput name="bic" [(ngModel)]="item.bic" #bic="ngModel" placeholder="BIC" [disabled]="disabled" [readonly]="readonly" class="uppercase" i18n-placeholder>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<!-- #region Bank name -->
	<div layout-gt-sm="row" layout="column">
		<mat-form-field flex layout-margin>
			<mat-label i18n>Bank name</mat-label>
			<input matInput name="bankName" [(ngModel)]="item.bankName" #bankName="ngModel" [disabled]="disabled" [readonly]="readonly" required placeholder="Bank name" i18n-placeholder>
			<mat-error *ngIf="bankName.errors?.required"><span i18n>Bank name</span><span i18n> is required</span></mat-error>
		</mat-form-field>
	</div>
	<!-- #endregion -->

	<!-- #region Approved -->
	<div layout-gt-sm="row" layout="column">
		<div layout-margin>
			<mat-checkbox name="approved" required [(ngModel)]="item.approved" [disabled]="disabled || readonly" i18n>SEPA Mandate approved</mat-checkbox>
		</div>
	</div>
	<!-- #endregion -->

	<!-- #region Custom fields -->
	<ng-content></ng-content>
	<!-- #endregion -->
</div>