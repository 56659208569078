import { Directive } from "@angular/core";
import { AbstractControl, NG_ASYNC_VALIDATORS, Validator } from "@angular/forms";
import { AccountData } from "../models/account-data";
import { UtilsService } from "../services/utils.service";

@Directive({
    selector: '[accountData]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: AccountDataValidatorDirective, multi: true }]
})
export class AccountDataValidatorDirective implements Validator {

    /**
     * Constructor
     */
    constructor(protected utilService: UtilsService) { }

    /**
     * Method that performs synchronous validation against the provided control.
     * @param control 
     */
    validate(control: AbstractControl): { [key: string]: any } | null {
        /* Get accountNumber and bankCode control elements */
        let accountNumberCtrl = control.root.get('accountNumber');
        let bankCodeCtrl = control.root.get('bankCode');
        let ibanCtrl = control.root.get('iban');
        let bicCtrl = control.root.get('bic');
        let bankNameCtrl = control.root.get('bankName');

        let accountData = <AccountData>{
            accountNumber: accountNumberCtrl ? accountNumberCtrl.value : null,
            bankCode: bankCodeCtrl ? bankCodeCtrl.value : null
        };

        /* Account number & bank code are required to validate */
        if (!control.value || !control.dirty || !accountData.accountNumber || !accountData.bankCode) {
            return new Promise(resolve => resolve(null));
        }

        return new Promise(resolve =>
            this.utilService.accountDataToIban(accountData).subscribe(ibanValidation => {
                if (ibanValidation.valid) {
                    /* Set iban */
                    if (ibanCtrl) { ibanCtrl.setValue(ibanValidation.iban); }

                    /* Set bic */
                    if (bicCtrl) { bicCtrl.setValue(ibanValidation.bic); }

                    /* Set bake name */
                    if (bankNameCtrl) { bankNameCtrl.setValue(ibanValidation.bankName); }

                    /* Reset errors */
                    accountNumberCtrl.setErrors(null);
                    bankCodeCtrl.setErrors(null);

                    resolve(null);
                } else {
                    /* Reset iban */
                    if (ibanCtrl) { ibanCtrl.setValue(''); }

                    /* Reset bic */
                    if (bicCtrl) { bicCtrl.setValue(''); }

                    /* Reset bake name */
                    if (bankNameCtrl) { bankNameCtrl.setValue(''); }

                    resolve({ invalidAccountData: ibanValidation.message });
                }
            }));
    }
}