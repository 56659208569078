import { Directive, ElementRef, Optional } from "@angular/core";
import { NgControl, NgModel } from "@angular/forms";

@Directive({ selector: '[ngModel]' })
export class NativeElementInjectorDirective {

    /**
     * Constructor
     * @param el 
     * @param control 
     * @param model 
     */
    constructor(private el: ElementRef, private control: NgControl, @Optional() private model: NgModel) {
        if (!!model) {
            (<any>model.control).nativeElement = el.nativeElement;
        } else {
            (<any>control).nativeElement = el.nativeElement;
        }
    }
}