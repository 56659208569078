import { AuditEntity } from "@app/common";
import { Column, Display, FormField, required } from "@messaia/cdk";
import { SyncApi } from "../enums/sync-api";
import { SyncOperation } from "../enums/sync-operation";
import { SyncStatus } from "../enums/sync-status";

export class SyncSchedule extends AuditEntity {
    @Column({ index: 1001, header: $localize`:@@id:ID`, arrowBefore: true, showGtMd: true })
    id: number;

    @FormField({ row: 1 })
    @required()
    @Column({ index: 0, enumType: SyncApi, maxWidth: 95 })
    @Display('API')
    api: SyncApi;

    @Column({ index: 10, enumType: SyncOperation })
    @Display($localize`:@@operation:Operation`)
    operation: SyncOperation;

    @Column({
        index: 20,
        enumType: SyncStatus,
        maxWidth: 130,
        cellNgClass: (row: SyncSchedule) => {
            return {
                'tc-orange-500': row.status == SyncStatus.Pending || row.status == SyncStatus.Retry,
                'tc-green-500': row.status == SyncStatus.Succeeded,
                'tc-lime-800': row.status == SyncStatus.PartiallySucceeded,
                'tc-black': row.status == SyncStatus.Cancelled,
                'tc-red-500': row.status == SyncStatus.Failed
            }
        },
        tooltip: (row: SyncSchedule) => row.message
    })
    @Display($localize`:@@status:Status`)
    status: SyncStatus;

    maxAttempts: number;

    @Column({ index: 30, maxWidth: 70, showGtMd: true, content: (row: SyncSchedule) => `${row.attempts}/${row.maxAttempts}` })
    @Display($localize`:@@attempts:Attempts`)
    attempts: number;

    @Column({ index: 900, header: $localize`:@@syncDate:Sync date`, arrowBefore: true, showGtMd: true, maxWidth: 80 })
    date: Date;

    /**
     * @property
     */
    public message: string;

    /**
     * @property
     */
    public request: string;

    /**
     * @property
     */
    public response: string;
}