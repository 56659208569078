import { AuditEntity } from "@app/common";
import { Api, Column, Display, FormField, FormFieldType, prop, required, Table } from "@messaia/cdk";
import { NotificationType } from "../enums/notification-type";
import { Customer } from "./customer";

@Api('notification')
@Table({ includes: ['Customer', 'Client'] })
export class Notification extends AuditEntity {
    @required()
    @FormField({
        row: 2,
        type: FormFieldType.VdChips,
        endpoint: 'customer',
        projection: 'id,displayName,number',
        chipTemplate: (row: any) => `
            <div layout="column" layout-align="center start" class="chip-option">
                <span>${row.displayName}</span>
                <span><small>#${row.number}</small></span>
            </div>`,
        autocompleteTemplate: (row: any) => `
            <div layout="column" layout-align="center start" class="chip-option">
                <span>${row.displayName}</span>
                <span><small>#${row.number}</small></span>
            </div>`,
        hide: (x: Notification) => !x.clientId
    })
    @Column({ content: (row: Notification) => row.customer?.displayName, filter: 'customer.displayName' })
    @Display($localize`:@@customer:Customer`)
    customerId: number;

    customer: Customer;

    @required()
    @FormField({ row: 1, endpoint: 'client?enabled=true&projection=id,displayName', optionText: 'displayName' })
    @Column({
        name: 'client.displayName',
        endpoint: 'client?enabled=true&projection=id,name',
        maxWidth: 250,
        showGtLg: true
    })
    @Display($localize`:@@client:Client`)
    clientId: number | null;

    @required()
    @FormField({ row: 3, enumType: NotificationType })
    @Column({ enumType: NotificationType, maxWidth: 100, showGtMd: true })
    @Display($localize`:@@type:Type`)
    type: NotificationType;

    subject: string;

    @required()
    @FormField({ row: 4, type: FormFieldType.Editor })
    @Display($localize`:@@content:Content`)
    content: string;
    link: string;

    @prop()
    @FormField({ row: 5 })
    @Column({ event: 'toggle', eventArgs: 'read' })
    @Display($localize`:@@read:Read`)
    read: boolean;
}