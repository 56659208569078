import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Salutation } from '@messaia/cdk';

@Component({
	selector: 'app-address-reactive-form',
	templateUrl: './address-reactive-form.component.html',
	styleUrls: ['./address-reactive-form.component.scss']
})
export class AddressReactiveFormComponent {

	/**
	 * form: FormGroup
	 */
	@Input()
	public form: FormGroup;

	/**
	 * extended: boolean = true
	 */
	@Input()
	public extended: boolean = true;

	/**
	 * hideCountry: boolean = false
	 */
	@Input()
	public hideCountry: boolean = false;

	/**
	 * The type of salutation enum
	 */
	public Salutation: typeof Salutation = Salutation;
}