import { prop, propArray, required } from "@messaia/cdk";
import { SftpConfig } from "./sftp-config";

export class ClientProvider {
    @prop()
    clientId: number;

    @required()
    providerId: number;

    @required()
    resellerId: number;

    @required()
    userName: string;

    @required({ conditionalExpression: (x: ClientProvider) => !x.clientId })
    passwordClear: string;

    @propArray(SftpConfig)
    sftpConfigs: SftpConfig[] = [];

    @prop()
    isTestMode: boolean;

    @prop()
    createCustomerBills: boolean;
}