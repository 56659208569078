import { Contact } from "@app/core";
import { ItemizedBillType } from "../enums/itemized-bill-type";

export class LineOption {
    taeLocation: string;
    currentLineCarrierId: string;
    witaProjectId: string;
    currentLinePhonePrefix: string;
    currentLinePhoneNumber: string;
    previousTenant: Contact = new Contact();
    activationContact: Contact = new Contact();
    itemizedBillType: ItemizedBillType;
    preferredDate: Date;

    /**
     * constructor
     * @param init 
     */
    public constructor(init?: Partial<LineOption>) {
        Object.assign(this, init);
    }
}