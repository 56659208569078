import { Component, Input } from '@angular/core';
import { Salutation } from '@messaia/cdk';
import { Address } from '../../models/address';

@Component({
	selector: 'app-address-item',
	templateUrl: 'address-item.html',
})
export class AddressItemComponent {
	/**
	 * The address data
	 */
	@Input()
	public address: Address;

	/**
	 * Shows the name
	 */
	@Input()
	public showName: boolean = false;

	/**
	 * Shows the country
	 */
	@Input()
	public showCountry: boolean = false;

	/**
	 * Shows the address inline
	 */
	@Input()
	public inline: boolean;

	/**
	 * Salutation enum
	 */
	public Salutation: typeof Salutation = Salutation;
}