import { Column } from "@messaia/cdk";
import { BaseEntity } from "./base-entity";

export abstract class AuditEntity extends BaseEntity {
    createdBy: string;
    @Column({ index: 1000, header: $localize`:@@createdDate:Created date`, arrowBefore: true, showGtSm: true })
    createdDate: Date;
    updatedBy: string;
    updatedDate: Date;

    /**
     * constructor
     * @param init 
     */
    public constructor(init?: Partial<AuditEntity>) {
        super(init);
        Object.assign(this, init);
    }
}