<div *ngIf="address">
	<div *ngIf="inline">
		<span *ngIf="address.firstName && showName">
			<span i18n>{address.salutation | enum:Salutation, select, salutation {salutation} other {{{address.salutation | enum:Salutation}}}}</span>
			{{address.firstName}} {{address.lastName}},
		</span>
		<span>
			<span>{{address.address1}} {{address.address2}}</span>
			<span *ngIf="address.address3"> {{address.address3}}</span>
		</span>
		<span>, {{address.postalCode}} {{address.city}}</span>
		<span *ngIf="address.country && showCountry">, <span i18n>{address.country, select, country {country} other {{{address.country}}}}</span></span>
	</div>

	<div *ngIf="!inline">
		<div *ngIf="address.firstName && showName">
			<span i18n>{address.salutation | enum:Salutation, select, salutation {salutation} other {{{address.salutation | enum:Salutation}}}}</span>
			{{address.firstName}} {{address.lastName}}
		</div>
		<div>{{address.address1}} {{address.address2}}
			<span *ngIf="address.address3"> {{address.address3}}</span>
		</div>
		<div>{{address.postalCode}} {{address.city}}</div>
		<div *ngIf="address.country && showCountry" i18n>{address.country, select, country {country} other {{{address.country}}}}</div>
	</div>
</div>