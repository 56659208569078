import { Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import { GenericCustomerService } from './generic-customer.service';

@Injectable({ providedIn: 'root' })
export class CustomerService extends GenericCustomerService<Customer> {

	/**
	 * Constructor
	 */
	constructor() {
		super('customer');
	}
}