import { ReminderType } from "@app/core";

export class ReminderInterval {
    id: number;
    type: ReminderType;
    days: number;

    /**
     * Constructor
     */
    constructor(type?: ReminderType, days?: number) {
        this.type = type;
        this.days = days;
    }
}