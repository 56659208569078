import { Column, IEntity } from "@messaia/cdk";

export abstract class BaseEntity implements IEntity {
    @Column({ filterOperator: '~', index: 1001, header: $localize`:@@id:ID`, arrowBefore: true, filterInputNumber: true })
    id: number;
    version: number;

    /**
     * constructor
     * @param init 
     */
    public constructor(init?: Partial<BaseEntity>) {
        Object.assign(this, init);
    }
}