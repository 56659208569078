<div *ngIf="item" layout-gt-sm="row" class="pad-sm">
	<!-- #region Left region -->
	<div flex>
		<table class="labeled">
			<tr>
				<td i18n>ID</td>
				<td>{{item.id}}</td>
			</tr>
			<tr>
				<td i18n>Created by</td>
				<td>{{item.createdBy}}</td>
			</tr>
			<tr>
				<td i18n>Created on</td>
				<td>{{item.createdDate | date:'dd.MM.yyyy HH:mm:ss'}}</td>
			</tr>
		</table>
		<ng-content select="[left]"></ng-content>
	</div>
	<!-- #endregion -->

	<!-- #region Middle region -->
	<div flex>
		<table class="labeled">
			<tr>
				<td i18n>Version</td>
				<td>{{item.version}}</td>
			</tr>
			<tr>
				<td i18n>Updated by</td>
				<td>{{item.updatedBy}}</td>
			</tr>
			<tr>
				<td i18n>Updated on</td>
				<td>{{item.updatedDate | date:'dd.MM.yyyy HH:mm:ss'}}</td>
			</tr>
		</table>
		<ng-content select="[middle]"></ng-content>
	</div>
	<!-- #endregion -->

	<!-- #region Right region -->
	<div flex>
		<ng-content select="[right]"></ng-content>
	</div>
	<!-- #endregion -->
</div>