import { BaseEntity } from "@app/common";
import { required } from "@messaia/cdk";

export class PriceBase extends BaseEntity {
    @required()
    net: number = 0;
    @required()
    gross: number = 0;
    @required()
    taxAmount: number = 0;

    /**
     * constructor
     * @param init 
     */
    public constructor(init?: Partial<PriceBase>) {
        super(init);
        Object.assign(this, init);
    }

    /**
     * Clones this object
     */
    public clone(): PriceBase {
        return Object.assign(this, { id: null });
    }
}