import { required } from "@messaia/cdk";
import { PriceUnit } from "../enums/price-unit";
import { PriceBase } from "./price-base";

export class UnitPrice extends PriceBase {
    @required()
    unit: PriceUnit = PriceUnit.Piece;
    @required()
    tax: number = 19;

    /**
     * constructor
     * @param init 
     */
    public constructor(init?: Partial<UnitPrice>) {
        super(init);
        Object.assign(this, init);
    }

    /**
     * Clones this object
     */
    public clone(): UnitPrice {
        return Object.assign(this, { id: null });
    }
}