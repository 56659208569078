import { Component, Input, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Salutation } from '@messaia/cdk';
import { Contact } from '../../models/contact';

let nextUniqueId = 0;

@Component({
	selector: 'app-contact-form',
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		useFactory: (controlContainer?: ControlContainer) => controlContainer,
		deps: [[new Optional(), NgForm]]
	}]
})
export class ContactFormComponent {

	/**
	 * Unique id
	 */
	public id: number = nextUniqueId++;

	/**
	 * Contact object
	 */
	@Input()
	public contact: Contact;

	/**
	 * Shows n extended contact form
	 */
	@Input()
	public extended: boolean = false;

	/**
	 * If true, sets input as required
	 */
	@Input()
	public required: boolean = true;

	/**
	 * If true, sets inputs as readonly
	 */
	@Input()
	public readonly: boolean = false;

	/**
	 * Hides phone prefix
	 */
	@Input()
	public hidePhonePrefix: boolean;

	/**
	 * Shows name
	 */
	@Input()
	public showName: boolean;

	/**
	 * Shows email
	 */
	@Input()
	public showEmail: boolean;

	/**
	 * The type of salutation enum
	 */
	public Salutation: typeof Salutation = Salutation;

	/**
	 * Phone prefix validation pattern
	 */
	public phonePrefixPattern: string = '^(0\\d{2,6})$';

	/**
	 * Phone number validation pattern
	 */
	public phoneNumberPattern: string = '^(0\\d{6,30})$';
}