<!-- #region Title -->
<mat-toolbar *ngIf="header" dense>{{header}}</mat-toolbar>
<!-- #endregion-->

<div *ngIf="item" [ngClass]="{'has-header': header && !cssClass}" class="{{cssClass}}" flex>
	<div layout="column" layout-gt-sm="row" *ngIf="!total">
		<!-- #region Unit -->
		<mat-form-field flex layout-margin *ngIf="!hideUnit">
			<mat-label i18n>Unit</mat-label>
			<vd-select [(ngModel)]="item.unit" #unit="ngModel" [enum]="PriceUnit" layout="column" name="unit{{id}}" placeholder="Unit" [disabled]="readonly" i18n-placeholder required flex></vd-select>
			<mat-error *ngIf="unit.errors?.required"><span i18n>Unit</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Tax -->
		<mat-form-field flex layout-margin *ngIf="!hideTax">
			<mat-label i18n>Tax</mat-label>
			<input matInput type="number" min="0" name="tax{{id}}" [(ngModel)]="item.tax" min="0" (ngModelChange)="updatePrices('tax', $event)" #tax="ngModel" [required]="required" [disabled]="disabled" [readonly]="readonly" placeholder="Tax" i18n-placeholder>
			<mat-error *ngIf="tax.errors?.required"><span i18n>Tax</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<div layout="column" layout-gt-sm="row">
		<!-- #region PriceNet -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Net</mat-label>
			<input matInput type="number" name="net{{id}}" [(ngModel)]="item.net" (ngModelChange)="updatePrices('net', $event)" #net="ngModel" [required]="required" [disabled]="disabled" [readonly]="readonly" placeholder="Net" i18n-placeholder>
			<mat-error *ngIf="net.errors?.required"><span i18n>Net</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region PriceTaxed -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Taxed</mat-label>
			<input matInput type="number" name="gross{{id}}" [(ngModel)]="item.gross" readonly (ngModelChange)="updatePrices('gross', $event)" #priceTaxed="ngModel" [required]="required" [disabled]="disabled" [readonly]="readonly" placeholder="Taxed" i18n-placeholder>
			<mat-error *ngIf="priceTaxed.errors?.required"><span i18n>Taxed</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region TaxAmount -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Tax amount</mat-label>
			<input matInput type="number" name="taxAmount{{id}}" [(ngModel)]="item.taxAmount" readonly #taxAmount="ngModel" [required]="required" [disabled]="disabled" [readonly]="readonly" placeholder="Tax amount" i18n-placeholder>
			<mat-error *ngIf="taxAmount.errors?.required"><span i18n>Tax amount</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<!-- #region Custom fields -->
	<ng-content></ng-content>
	<!-- #endregion -->
</div>