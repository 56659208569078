<mat-accordion [multi]="multiple">
    <!-- #region Line Address -->
    <mat-expansion-panel id="lines.{{item.id}}.address" class="flat">
        <mat-expansion-panel-header>
            <mat-panel-title i18n>Line address</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="pad-sm bgc-white-4">
            <app-address-form [address]="item.address" extended="true" [readonly]="readonly || (item.id && !item.addressEditable)">
                <div layout="row" flex>
                    <mat-form-field flex layout-margin appearance="outline">
                        <mat-label i18n>Notice</mat-label>
                        <textarea matInput autocomplete="off" name="addressNotice{{id}}" [(ngModel)]="item.address.notice" #notice="ngModel" maxlength="255" [readonly]="readonly || (item.id && !item.addressEditable)"></textarea>
                        <mat-error *ngIf="notice.errors?.required"><span i18n>Notice</span><span i18n> is required</span></mat-error>
                        <mat-hint i18n="@@connectionAddressHint">i.e. ground floor on the left</mat-hint>
                    </mat-form-field>
                </div>
            </app-address-form>
        </div>
        <ng-content select="[addressFooter]"></ng-content>
        <ng-container *ngIf="item.addressEditable">
            <ng-template *ngTemplateOutlet="panelFooter" [ngTemplateOutletContext]="{item: item.address}"></ng-template>
        </ng-container>
        <!-- #region Edit actions -->
        <mat-action-row *ngIf="item.addressEditable && (edit?.observed || back?.observed)">
            <button type="button" mat-flat-button color="primary" *ngIf="edit?.observed" (click)="edit.emit('lines.' + item.id + '.address')" i18n>Edit</button>
            <button type="button" mat-button *ngIf="back?.observed" (click)="back.emit()" i18n>Cancel</button>
            <span flex></span>
        </mat-action-row>
        <!-- #endregion -->
    </mat-expansion-panel>
    <!-- #endregion -->

    <!-- #region Line Options -->
    <mat-expansion-panel id="lines.{{item.id}}.options" [expanded]="multiple" *ngIf="item.lineOption" class="flat">
        <mat-expansion-panel-header>
            <mat-panel-title i18n>Line options</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="pad-sm bgc-white-4">
            <!-- #region Phone prefix & number -->
            <app-phone-form [item]="item.lineOption" modelPrefix="currentLine" [readonly]="readonly" [required]="false"></app-phone-form>
            <!-- #endregion -->

            <div layout-gt-sm="row" layout="column">
                <!-- #region TAE Location -->
                <mat-form-field flex layout-margin>
                    <mat-label i18n>TAE location</mat-label>
                    <input matInput name="taeLocation{{id}}" [(ngModel)]="item.lineOption.taeLocation" #tAELocation="ngModel" [readonly]="readonly" placeholder="TAE location" i18n-placeholder>
                    <mat-error *ngIf="tAELocation.errors?.required"><span i18n>TAE location</span><span i18n> is required</span></mat-error>
                </mat-form-field>
                <!-- #endregion -->

                <!-- #region Itemized bill -->
                <mat-form-field flex layout-margin>
                    <mat-label i18n>Itemized bill</mat-label>
                    <vd-select name="itemizedBillType{{id}}" [(ngModel)]="item.lineOption.itemizedBillType" [enum]="ItemizedBillType" #itemizedBillType="ngModel" [disabled]="readonly" placeholder="Itemized bill" i18n-placeholder required flex></vd-select>
                    <mat-error *ngIf="itemizedBillType.errors?.required"><span i18n>Itemized bill</span><span i18n> is required</span></mat-error>
                </mat-form-field>
                <!-- #endregion -->
            </div>
        </div>
        <ng-template *ngTemplateOutlet="panelFooter" [ngTemplateOutletContext]="{item: item.lineOption}"></ng-template>
        <!-- #region Edit actions -->
        <mat-action-row *ngIf="edit?.observed || back?.observed">
            <button type="button" mat-flat-button color="primary" *ngIf="edit?.observed" (click)="edit.emit('lines.' + item.id + '.options')" i18n>Edit</button>
            <button type="button" mat-button *ngIf="back?.observed" (click)="back.emit()" i18n>Cancel</button>
            <span flex></span>
        </mat-action-row>
        <!-- #endregion -->
    </mat-expansion-panel>
    <!-- #endregion -->

    <!-- #region Contact / Manager -->
    <mat-expansion-panel id="lines.{{item.id}}.activation-contact" [expanded]="multiple" *ngIf="item.lineOption?.activationContact" class="flat">
        <mat-expansion-panel-header>
            <mat-panel-title i18n>Contact / Manager</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="pad-sm bgc-white-4">
            <app-contact-form [contact]="item.lineOption.activationContact" [readonly]="readonly" showName="true" showEmail="true"></app-contact-form>
        </div>
        <ng-template *ngTemplateOutlet="panelFooter" [ngTemplateOutletContext]="{item: item.lineOption?.activationContact}"></ng-template>
        <mat-action-row *ngIf="edit?.observed || back?.observed">
            <button type="button" mat-flat-button color="primary" *ngIf="edit?.observed" (click)="edit.emit('lines.' + item.id + '.activation-contact')" i18n>Edit</button>
            <button type="button" mat-button *ngIf="back?.observed" (click)="back.emit()" i18n>Cancel</button>
            <span flex></span>
        </mat-action-row>
    </mat-expansion-panel>
    <!-- #endregion -->

    <!-- #region Previous tenant -->
    <mat-expansion-panel id="lines.{{item.id}}.previous-tenant" [expanded]="multiple" *ngIf="item.lineOption?.previousTenant" class="flat">
        <mat-expansion-panel-header>
            <mat-panel-title i18n>Previous tenant</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="pad-sm bgc-white-4">
            <app-contact-form [contact]="item.lineOption.previousTenant" [readonly]="readonly" required="false"></app-contact-form>
        </div>
        <ng-template *ngTemplateOutlet="panelFooter" [ngTemplateOutletContext]="{item: item.lineOption?.previousTenant}"></ng-template>
        <mat-action-row *ngIf="edit?.observed || back?.observed">
            <button type="button" mat-flat-button color="primary" *ngIf="edit?.observed" (click)="edit.emit('lines.' + item.id + '.previous-tenant')" i18n>Edit</button>
            <button type="button" mat-button *ngIf="back?.observed" (click)="back.emit()" i18n>Cancel</button>
            <span flex></span>
        </mat-action-row>
    </mat-expansion-panel>
    <!-- #endregion-->

    <!-- #region References -->
    <mat-expansion-panel id="lines.{{item.id}}.references" [expanded]="multiple" *ngIf="showReferences && item.syncApis" class="flat">
        <mat-expansion-panel-header>
            <mat-panel-title i18n>References</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list role="list" class="bgc-white-4 pad-sm" *ngIf="item.syncApis">
            <mat-list-item role="listitem" class="bgc-grey-100" *ngFor="let reference of item.syncApis">
                <span>{{reference.syncApi | enum:SyncApi}}</span>
                <span flex></span>
                <span class="na">{{reference.externalId}}</span>
            </mat-list-item>
            <mat-list-item role="listitem" class="bgc-grey-100 txt-italic" *ngIf="!item.syncApis?.length" i18n>No data to display</mat-list-item>
        </mat-list>
        <mat-action-row *ngIf="back?.observed">
            <button type="button" mat-flat-button color="accent" (click)="back.emit()" i18n>Close</button>
            <span flex></span>
        </mat-action-row>
    </mat-expansion-panel>
    <!-- #endregion-->

    <ng-template *ngTemplateOutlet="footer" [ngTemplateOutletContext]="{item: item}"></ng-template>
    <ng-content select="[footer]"></ng-content>
</mat-accordion>