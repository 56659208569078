<div *ngIf="item">
    <div layout-gt-sm="row" layout="column">
        <!-- #region Name -->
        <mat-form-field layout-margin flex-gt-sm="60" flex>
            <mat-label i18n>Name</mat-label>
            <input matInput name="name{{id}}" [readonly]="readonly" [(ngModel)]="item.name" [required]="required" #name="ngModel" placeholder="Name" i18n-placeholder>
            <mat-error *ngIf="name.errors?.required"><span i18n>Name</span><span i18n> is required</span></mat-error>
        </mat-form-field>
        <!-- #endregion -->

        <!-- #region Billing period -->
        <mat-form-field layout-margin flex-gt-sm="30" flex *ngIf="showMinimumTerm">
            <mat-label i18n>Billing period</mat-label>
            <input type="number" matInput name="minimumTerm" min="0" [(ngModel)]="item.minimumTerm" #minimumTerm="ngModel" required placeholder="Billing period" i18n-placeholder>
            <mat-hint i18n>From X months</mat-hint>
            <mat-error *ngIf="minimumTerm.errors?.required"><span i18n>Billing period</span><span i18n> is required</span></mat-error>
        </mat-form-field>
        <!-- #endregion -->
    </div>

    <!-- #region Prices -->
    <div layout="column" layout-gt-sm="row">
        <app-price-form [item]="item" [required]="required" [readonly]="readonly" hideUnit="true" flex></app-price-form>
    </div>
    <!-- #endregion -->
</div>