import { BreakpointObserver, Breakpoints, BreakpointState, MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthHelper, AuthUser, BaseComponent } from "@messaia/cdk";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html'
})
export class MainComponent extends BaseComponent {

	/**
	 * Is handset
	 */
	public isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);

	/**
	 * Mobile query
	 */
	public mobileQuery: MediaQueryList;

	/**
	 * Mobile query listener
	 */
	private mobileQueryListener: () => void;

	/**
	 * Subtile
	 */
	public subtitle: string = '';

	/**
	 * Copyright
	 */
	public copyright: string = environment.copyright;

	/**
	 * Current user object
	 */
	public user: AuthUser = AuthHelper.user;

	/**
	 * Numer of unread messages
	 */
	public unreadMessageCount: number;

	/**
	 * Constructor
	 */
	constructor(
		private mediaMatcher: MediaMatcher,
		private breakpointObserver: BreakpointObserver,
		private oidcSecurityService: OidcSecurityService,
		protected route: ActivatedRoute,
		protected changeDetectorRef: ChangeDetectorRef
	) {
		super(route, changeDetectorRef)

		this.mobileQuery = mediaMatcher.matchMedia('(max-width: 800px)');
		this.mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this.mobileQueryListener);
	}

	/**
	 * Refresh session
	 */
	public refreshSession() {
		this.oidcSecurityService.authorize();
	}

	/**
	 * Logs user out.
	 */
	public logout() {
		this.oidcSecurityService.logoff();
	}
}