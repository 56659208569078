import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@app/core';
import { AppSetting } from '@messaia/cdk';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { NotificationDialogComponent } from './notification/notification-dialog.component';
import { NotificationComponent } from './notification/notification.component';

registerLocaleData(localeDe);

const appearance: MatFormFieldDefaultOptions = {
	appearance: 'outline'
};

@NgModule({
	exports: [
		MatToolbarModule,
		MatSidenavModule,
		MatBadgeModule,
		MatMenuModule
	]
})
export class MaterialModule { }

@NgModule({
	declarations: [
		AppComponent,
		MainComponent,
		NotificationComponent,
		NotificationDialogComponent
	],
	imports: [
		CoreModule,
		MaterialModule,
		ReactiveFormsModule,
		AppRoutingModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
	],
	exports: [],
	providers: [
		{
			provide: AppSetting,
			useValue: <AppSetting>environment
		}, {
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: appearance
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }