import { AuditEntity } from '@app/common';
import { Document } from '@app/documents-base';
import { Column, Display, Table } from "@messaia/cdk";
import { ActivityChannel } from "../enums/activity-channel";
import { ActivityType } from "../enums/activity-type";
import { Customer } from './customer';

@Table({
	endpoint: 'activity',
	includes: ['Customer', 'Client'],
	addable: false,
	showAction: false,
	editable: false,
	detailsTemplate: `<div [innerHtml]="description"></div>`
})
export class Activity extends AuditEntity {
	type: ActivityType;
	channel: ActivityChannel;

	@Column({ contentLength: 40 })
	@Display($localize`:@@description:Description`)
	description: string;
	details: string;

	@Column({ name: 'client.displayName', endpoint: 'client?enabled=true&projection=id,name', maxWidth: 200, showGtLg: true })
	@Display($localize`:@@client:Client`)
	clientId: number;
	customerId: number;

	@Column({ name: 'customer.number', filter: 'customer.number', maxWidth: 90, showGtLg: true })
	@Display($localize`:@@customerNumer:Customer numer`)
	customer: Customer;
	documents: Document[] = [];
}