import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {

	/**
	 * loaded: boolean
	 */
	public loaded: boolean;

	/**
	 * constructor
	 * @param oidcSecurityService 
	 */
	constructor(private oidcSecurityService: OidcSecurityService) { }

	/**
	 * Called after the constructor, initializing input properties, 
	 * and the first call to ngOnChanges.
	 */
	ngOnInit(): void {
		(async () => {
			this.loaded = false;
			await new Promise(y => setTimeout(y, 1500));
			this.loaded = true;
		})();
	}

	/**
	 * Logs user in.
	 */
	public login() {
		this.oidcSecurityService.authorize();
	}

	/**
	 * Logs user out.
	 */
	public logout() {
		this.oidcSecurityService.logoff();
	}
}