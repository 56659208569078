import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { Salutation } from '../../enums/salutation';

@Component({
	selector: 'app-personal-data-reactive-form',
	templateUrl: './personal-data-reactive-form.component.html',
	styleUrls: ['./personal-data-reactive-form.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class PersonalDataReactiveFormComponent {
	/**
	 * Personal data
	 */
	@Input()
	public form: FormGroup;

	/**
	 * Form options
	 */
	@Input()
	public options: any = {};

	/**
	 * The type of salutation enum
	 */
	public Salutation: typeof Salutation = Salutation;

	/**
	 * Phone prefix validation pattern
	 */
	@Input()
	public phonePrefixPattern: string = '^(0\\d{2,6})$';

	/**
	 * Phone number validation pattern
	 */
	@Input()
	public phoneNumberPattern: string = '^(\\d{3,30})$';

	/**
	 * Min birthdate date
	 */
	public minBirthdateDate: Date = new Date().addYears(-130);

	/**
	 * Min birthdate date
	 */
	public maxBirthdateDate: Date = new Date().addYears(-18);

	/**
	 * Readonly?
	 */
	@Input()
	public readonly: boolean;
}