import { Component, ContentChild, EventEmitter, Input, Optional, Output, TemplateRef } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { SyncApi } from "@app/core";
import { BaseComponent } from "@messaia/cdk";
import { ItemizedBillType } from "../../enums/itemized-bill-type";
import { CustomerConnection } from "../../models/customer-connection";

let nextUniqueId = 0;

@Component({
    selector: 'app-customer-connection-form',
    templateUrl: './customer-connection-form.component.html',
    styleUrls: ['./customer-connection-form.component.scss'],
    viewProviders: [{
        provide: ControlContainer,
        useFactory: (controlContainer?: ControlContainer) => controlContainer,
        deps: [[new Optional(), NgForm]]
    }]
})
export class CustomerConnectionFormComponent extends BaseComponent {

    /**
     * Unique id
     */
    public id: number = nextUniqueId++;

    /**
     * item: LineOption
     */
    @Input()
    public item: CustomerConnection;

    /**
     * multiple: Boolean
     */
    @Input()
    public multiple: boolean;

    /**
     * readonly: Boolean
     */
    @Input()
    public readonly: boolean;

    /**
     * showReferences: Boolean
     */
    @Input()
    public showReferences: boolean;

    /**
     * Edit event handler
     */
    @Output()
    public edit: EventEmitter<any> = new EventEmitter<any>();

    /**
    * Back event handler
    */
    @Output()
    public back: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Template ref for panel footer
     */
    @ContentChild('panelFooter')
    public panelFooter: TemplateRef<any>;

    /**
     * Template ref for custom fields
     */
    @ContentChild('footer')
    public footer: TemplateRef<any>;

    /**
     * ItemizedBill enum type
     */
    public ItemizedBillType: typeof ItemizedBillType = ItemizedBillType;

    /**
     * SyncApi enum type
     */
    public SyncApi: typeof SyncApi = SyncApi;
}