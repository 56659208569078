export enum Unit {
    None,
    Second,
    Minute,
    Hour,
    Day,
    Month,
    Year,
    KByte,
    MByte,
    GByte,
    TByte
}