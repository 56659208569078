import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceLocator } from '@messaia/cdk';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Pass untouched request through to the next request handler.
 */
@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

    /**
     * Keep in mind that injecting OidcSecurityService into the interceptor via the 
     * constructor results in a cyclic dependency. To avoid this use the injector instead.
     */
    private oidcSecurityService: OidcSecurityService;

    /**
     * Constructor
     * @param router
     */
    constructor(private router: Router) { }

    /**
     * Intercept an outgoing `HttpRequest` and optionally transform it or the
     * response.
     *
     * Typically an interceptor will transform the outgoing request before returning
     * `next.handle(transformedReq)`. An interceptor may choose to transform the
     * response event stream as well, by applying additional Rx operators on the stream
     * returned by `next.handle()`.
     *
     * More rarely, an interceptor may choose to completely handle the request itself,
     * and compose a new event stream instead of invoking `next.handle()`. This is
     * acceptable behavior, but keep in mind further interceptors will be skipped entirely.
     *
     * It is also rare but valid for an interceptor to return multiple responses on the
     * event stream for a single request.
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;

        /* Get the OidcSecurityService using the injector */
        if (this.oidcSecurityService === undefined) {
            this.oidcSecurityService = ServiceLocator.getService(OidcSecurityService);
        }

        if (this.oidcSecurityService !== undefined) {
            /* Get the access token */
            const token = this.oidcSecurityService.getAccessToken().subscribe((token) => {
                /* Clone the request and replace the original headers with cloned headers, updated with the authorization */
                requestToForward = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
            });
        } else {
            console.debug('OidcSecurityService undefined: NO auth header!');
        }

        /* Send cloned request with header to the next handler */
        return next.handle(requestToForward).pipe(
            tap(
                /* Succeeds when there is a response; ignore other events */
                _ => { },

                /* Operation failed; error is an HttpErrorResponse */
                error => {
                    /* Unauthorized, redirect to unauthorized page */
                    if (error.status == 401) {
                        this.router.navigate(['/unauthorized']);
                    }
                })
        );
    }
}