<div *ngIf="contact">

	<div layout-gt-sm="row" layout="column">
		<!-- #region Salutation -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Salutation</mat-label>
			<vd-select [(ngModel)]="contact.salutation" [disabled]="readonly" [enum]="Salutation" name="contactSalutation{{id}}" #salutation="ngModel" placeholder="Salutation" i18n-placeholder [required]="required" flex></vd-select>
			<mat-error *ngIf="salutation.errors?.required"><span i18n>Salutation</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Title -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Title</mat-label>
			<input matInput autocomplete="off" name="contactTitle{{id}}" [(ngModel)]="contact.title" #title="ngModel" placeholder="Title" [readonly]="readonly" i18n-placeholder>
			<mat-error *ngIf="title.errors?.required"><span i18n>Title</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<!-- #region Company -->
	<div layout-gt-sm="row" layout="column" *ngIf="showName">
		<mat-form-field flex="50" layout-margin>
			<mat-label i18n>Company</mat-label>
			<input matInput name="name{{id}}" [readonly]="readonly" [(ngModel)]="contact.name" #name="ngModel" placeholder="Company" i18n-placeholder>
			<mat-error *ngIf="name.errors?.required"><span i18n>Company</span><span i18n> is required</span></mat-error>
		</mat-form-field>
	</div>
	<!-- #endregion -->

	<div layout-gt-sm="row" layout="column">
		<!-- #region First name -->
		<mat-form-field flex="50" layout-margin>
			<mat-label i18n>First name</mat-label>
			<input matInput name="contactFirstName{{id}}" [readonly]="readonly" [(ngModel)]="contact.firstName" [required]="required" #firstName="ngModel" placeholder="First name" i18n-placeholder>
			<mat-error *ngIf="firstName.errors?.required"><span i18n>First name</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Last name -->
		<mat-form-field flex="50" layout-margin>
			<mat-label i18n>Last name</mat-label>
			<input matInput name="contactLastName{{id}}" [readonly]="readonly" [(ngModel)]="contact.lastName" [required]="required" #lastName="ngModel" placeholder="Last name" i18n-placeholder>
			<mat-error *ngIf="lastName.errors?.required"><span i18n>Last name</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<div layout="column" layout-gt-sm="row" flex>
		<!-- #region Phone number -->
		<mat-form-field flex layout-margin>
			<mat-label i18n>Phone number</mat-label>
			<input matInput name="contactPhoneNumber{{id}}" [onlyNumber]="true" [(ngModel)]="contact.phoneNumber" #phoneNumber="ngModel" [required]="required" [readonly]="readonly" placeholder="Phone number" i18n-placeholder>
			<mat-error *ngIf="phoneNumber.errors?.required"><span i18n>Phone number</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Alternative phone number -->
		<mat-form-field flex layout-margin *ngIf="!hidePhonePrefix">
			<mat-label i18n>Alternative phone number</mat-label>
			<input matInput name="contactPhonePrefix{{id}}" [onlyNumber]="true" [(ngModel)]="contact.phonePrefix" #phonePrefix="ngModel" [readonly]="readonly" placeholder="Alternative phone number" i18n-placeholder>
			<mat-error *ngIf="phonePrefix.errors?.required"><span i18n>Alternative phone number</span><span i18n> is required</span></mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<!-- #region Email -->
	<div layout-gt-sm="row" layout="column" *ngIf="showEmail">
		<mat-form-field flex layout-margin>
			<mat-label i18n>Email</mat-label>
			<input matInput type="email" name="email{{id}}" [(ngModel)]="contact.email" #email="ngModel" email [readonly]="readonly" placeholder="Email" i18n-placeholder>
			<mat-error *ngIf="email.errors?.required"><span i18n>Email</span><span i18n> is required</span></mat-error>
			<mat-error *ngIf="!email.errors?.required && email.errors?.email"><span i18n>Email</span><span i18n> is invalid</span></mat-error>
		</mat-form-field>
	</div>
	<!-- #endregion -->

	<!-- #region Notice -->
	<div layout="row" flex>
		<mat-form-field flex layout-margin>
			<mat-label i18n>Notice</mat-label>
			<input matInput autocomplete="off" name="contactNotice{{id}}" [(ngModel)]="contact.notice" #notice="ngModel" [readonly]="readonly" placeholder="Notice" i18n-placeholder>
			<mat-error *ngIf="notice.errors?.required"><span i18n>Notice</span><span i18n> is required</span></mat-error>
		</mat-form-field>
	</div>
	<!-- #endregion -->

	<!-- #region Custom fields -->
	<ng-content></ng-content>
	<!-- #endregion -->
</div>