import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { PriceUnit } from '../../enums/price-unit';
import { UnitPrice } from '../../models/unit-price';

let nextUniqueId = 0;

@Component({
	selector: 'app-price-form',
	templateUrl: './price-form.component.html',
	styleUrls: ['./price-form.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class PriceFormComponent {

	/**
	 * Unique id
	 */
	public id: number = nextUniqueId++;

	/**
	 * The price object
	 */
	@Input()
	public item: UnitPrice;

	/**
	 * Hides tax input
	 */
	@Input()
	public required: boolean = true;

	/**
	 * Total price
	 */
	@Input()
	public total: boolean;

	/**
	 * Hides unit input
	 */
	@Input()
	public hideUnit: boolean;

	/**
	 * Hides tax input
	 */
	@Input()
	public hideTax: boolean;

	/**
	 * Sets inputs as disabled
	 */
	@Input()
	public disabled: boolean;

	/**
	 * Sets inputs as readonly
	 */
	@Input()
	public readonly: boolean;

	/**
	 * Sets header
	 */
	@Input()
	public header: boolean;

	/**
	 * Sets cssClass
	 */
	@Input()
	public cssClass: string;

	/**
	 * PriceUnit enum type
	 */
	public PriceUnit: typeof PriceUnit = PriceUnit;

	/**
	 * Change callback
	 */
	@Output()
	public change: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Updates prices
	 * @param field 
	 * @param value 
	 */
	public updatePrices(field: string, value: any) {
		switch (field) {
			case 'net':
			case 'tax':
				this.item.gross = this.fraction(this.item.net * (1 + this.item.tax / 100));
				this.item.taxAmount = this.fraction(this.item.gross - this.item.net);
				break;

			case 'gross':
				this.item.net = this.fraction(this.item.gross / (1 + this.item.tax / 100));
				this.item.taxAmount = this.fraction(this.item.gross - this.item.net);
				break;

			default:
				break;
		}

		this.change.emit(field);
	}

	/**
	 * Returns a string representing a number in fixed-point notation.
	 * @param number 
	 * @param fraction 
	 */
	private fraction(number: number, fraction: number = 4): number {
		return parseFloat(number.toFixed(4));
	}
}