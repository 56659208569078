import { IEntity, prop, required } from "@messaia/cdk";

export class Address implements IEntity {
	id: number;

	@prop()
	salutation: number;

	@prop()
	title: string;

	@prop()
	firstName: string;

	@prop()
	lastName: string;

	@required()
	address1: string;

	@required()
	address2: string;

	@prop()
	address3: string;

	@required()
	postalCode: string;

	@required()
	city: string;

	@prop()
	state: string;

	@required()
	country: string = 'DE';

	@prop()
	notice: string;

	/**
	 * constructor
	 * @param init 
	 */
	public constructor(init?: Partial<Address>) {
		Object.assign(this, init);
	}
}