import { SyncOperation } from '@app/core';
import { AuthHelper, GenericService, Utils } from '@messaia/cdk';
import { Observable } from 'rxjs';
import { Roles } from '../enums/roles';
import { Customer } from '../models/customer';

export abstract class GenericCustomerService<TCustomer extends Customer> extends GenericService<TCustomer> {

	/**
	 * Customer roles
	 */
	public roles: typeof Roles = Roles;

	/**
	 * Constructor
	 */
	constructor(public endpoint: string) {
		super(endpoint);
	}

	/**
	 * Schedules a sync for the item with the specified ID
	 * @param id 
	 * @param operation 
	 */
	public scheduleItemSync(id: number, operation: SyncOperation) {
		return this.update(`scheduleSync/${id}/${operation}`, null);
	}

	/**
	 * Schedules a sync for the items with the specified ids
	 * @param ids 
	 * @param operation 
	 */
	public scheduleItemsSync(ids: number[], operation: SyncOperation) {
		return this.update(`scheduleSync`, null, null, { params: Utils.toHttpParams({ ids: ids, operation: operation }) });
	}

	/**
	 * Changes user's password
	 * @param @param entity The entity to update 
	 */
	public changePassword(entity: any): Observable<any> {
		return super.create(entity, '/changePassword');
	}

	/**
	 * Unlocks u lockeded out user
	 * @param id The id of the user to unlock
	 */
	public unlock(id: number): Observable<any> {
		return this.update(`lockout/${id}`, { locked: false });
	}

	/**
	 * Sends a password reset request to the end user
	 * @param id The id of the user
	 */
	public sendResetPasswordRequest(id: number): Observable<any> {
		return this.update(`sendResetPasswordRequest/${id}`, null);
	}

	/**
	 * Checks if the current user has on of the specified roles
	 * @param roles 
	 */
	public hasRole(roles: any): boolean {
		return AuthHelper.hasRole(roles);
	}
}