import { Component, Input, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { AccountData } from '../../models/account-data';
import { Sepa } from '../../models/sepa';

@Component({
	selector: 'app-sepa-form',
	templateUrl: './sepa-form.component.html',
	styleUrls: ['./sepa-form.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SepaFormComponent {
	/**
	 * Personal data
	 */
	@Input()
	public item: Sepa;

	/**
	 * Disabled?
	 */
	@Input()
	public disabled: boolean;

	/**
	 * Readonly?
	 */
	@Input()
	public readonly: boolean;

	/**
	 * accountData: object
	 */
	public accountData: AccountData = new AccountData();

	/**
	 * A ref to iban input element
	 */
	@ViewChild('iban', { static: false })
	public ibanInput: NgModel;

	/**
	 * This method is used to sanitize the IBAN by removing 
	 * white spaces and converting it to uppercase.
	 */
	public sanitizeIban(): void {
		this.item.iban = this.item.iban.replace(/\s/g, '').toUpperCase();
		this.ibanInput.control.setValue(this.item.iban, { emitEvent: false, onlySelf: false });
	}
}