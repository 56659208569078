import { Injectable } from '@angular/core';
import { MatDateFormats } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { Moment } from 'moment';

export const MOMENT_DATE_FORMATS: MatDateFormats = {
	parse: {
		dateInput: 'DD.MM.YYYY'
	},
	display: {
		dateInput: 'DD.MM.YYYY',
		monthYearLabel: 'MMMM Y',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM Y'
	}
};

@Injectable({ providedIn: 'root' })
export class GermanDateAdapter extends MomentDateAdapter {

	createDate(year: number, month: number, date: number): Moment {
		/*
		Moment.js will create an invalid date if any of the components are out of bounds, 
		but we explicitly check each case so we can throw more descriptive errors.
		*/
		if (month < 0 || month > 11) {
			throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
		}

		if (date < 1) {
			throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
		}

		let result = moment.utc({ year, month, date }).locale(this.locale);

		/* If the result isn't valid, the date must have been out of bounds for this month */
		if (!result.isValid()) {
			throw Error(`Invalid date "${date}" for month with index "${month}".`);
		}

		return result;
	}
}