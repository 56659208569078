import { ReminderType } from "@app/core";
import { prop, required } from "@messaia/cdk";
import { ReminderInterval } from "./reminder-interval";

export class ClientSetting {
	@required()
	frontendUrl: string;

	@prop()
	ticketDueSpan: number = 48;

	@prop()
	createBillsManually: boolean = false;

	@prop()
	billCreationDay: number = 21;

	@prop()
	ticketAutoAssigneeId: number;

	@prop()
	notifyCustomerOnChange: boolean;

	reminderIntervals: ReminderInterval[] = [
		new ReminderInterval(ReminderType.Reminder1),
		new ReminderInterval(ReminderType.Reminder2),
		new ReminderInterval(ReminderType.Reminder3)
	];

	/**
	 * constructor
	 * @param init 
	 */
	public constructor(init?: Partial<ClientSetting>) {
		Object.assign(this, init);
	}
}