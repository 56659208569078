<div class="main-container" layout="column" flex [class.mobile]="mobileQuery.matches">

	<!-- #region Main toolbar -->
	<mat-toolbar color="primary" class="main-toolbar mat-elevation-z6">
		<button mat-icon-button (click)="snav.toggle()">
			<mat-icon>menu</mat-icon>
		</button>
		<h1 class="app-name">{{projectName}}</h1>
		<span flex></span>
		<app-notification></app-notification>
		<a mat-icon-button (click)="logout()">
			<mat-icon>exit_to_app</mat-icon>
		</a>
	</mat-toolbar>
	<!-- #endregion -->

	<mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
		<mat-sidenav class="main-sidenav" [opened]="!mobileQuery.matches" #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" layout="column" flex>
			<!-- #region Navigation list -->
			<ng-template let-item let-last="last" ngFor [ngForOf]="menu" flex>
				<vd-menu [items]="item.items" [activeExact]="false" (click)="mobileQuery.matches?snav.close():''" flex></vd-menu>
			</ng-template>
			<!-- #endregion -->

			<!-- #region Copyright footer -->
			<div class="main-footer">{{copyright}}</div>
			<!-- #endregion -->
		</mat-sidenav>

		<!-- #region Loading indicator -->
		<div *ngIf="false" layout="column" layout-fill>
			<div layout="column" layout-align="center center" flex>
				<mat-spinner></mat-spinner>
				<div class="md-title pad-top">Loading...</div>
			</div>
		</div>
		<!-- #endregion -->

		<!-- #region Main Content -->
		<mat-sidenav-content>
			<router-outlet></router-outlet>
		</mat-sidenav-content>
		<!-- #endregion -->
	</mat-sidenav-container>
</div>