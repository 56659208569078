import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthHelper, AuthUser } from '@messaia/cdk';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {

	/**
	 * Constructor
	 * @param router 
	 */
	constructor(private oidcSecurityService: OidcSecurityService, private router: Router) { }

	/**
	 * Checks if the route can be activated
	 * @param next 
	 * @param state 
	 */
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (this.isLoggedIn().subscribe()) {
			if (!this.hasRole(AuthHelper.user, next.data["roles"])) {
				this.router.navigate(['/forbidden']);
			}

			return true;
		}

		return false;
	}

	/**
	 * Checks if the current user has on of the specified roles
	 * @param roles 
	 */
	public hasRole(user: AuthUser, roles: string[]): boolean {
		return roles.some(role => {
			if (!user || !role) {
				return false;
			}

			var userRoles: string[] = Array.isArray(user.role) ? user.role : [user.role];
			return userRoles.some(r => r.toLowerCase() === role.toLowerCase());
		});
	}

	/**
	 * Checks if the user is logged in
	 */
	private isLoggedIn(): Observable<boolean | UrlTree> {
		return this.oidcSecurityService.isAuthenticated$.pipe(
			map(({ isAuthenticated }) => {
				/* Allow navigation if authenticated */
				if (isAuthenticated) {
					return true;
				}

				/* Redirect if not authenticated */
				return this.router.parseUrl('/unauthorized');
			})
		);
	}
}