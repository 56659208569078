<div *ngIf="form" [formGroup]="form">
	<div layout-gt-sm="row" layout="column" *ngIf="extended">
		<!-- #region Salutation -->
		<mat-form-field flex layout-margin appearance="outline">
			<mat-label i18n>Salutation</mat-label>
			<vd-select formControlName="salutation" [enum]="Salutation" flex></vd-select>
			<mat-error *ngFor="let errorMessage of form.controls.salutation['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Title -->
		<mat-form-field flex layout-margin appearance="outline">
			<mat-label i18n>Title</mat-label>
			<input matInput formControlName="title">
			<mat-error *ngFor="let errorMessage of form.controls.title['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<!-- #region First & last name -->
	<div layout-gt-sm="row" layout="column" *ngIf="extended">
		<!-- #region First name -->
		<mat-form-field flex="50" layout-margin appearance="outline">
			<mat-label i18n>First name</mat-label>
			<input matInput formControlName="firstName">
			<mat-error *ngFor="let errorMessage of form.controls.firstName['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Last name -->
		<mat-form-field flex="50" layout-margin appearance="outline">
			<mat-label i18n>Last name</mat-label>
			<input matInput formControlName="lastName">
			<mat-error *ngFor="let errorMessage of form.controls.lastName['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>
	<!-- #endregion -->

	<!-- #region Address 1 & 2 & 3 -->
	<div layout-gt-sm="row" layout="column">
		<!-- #region Address 1-->
		<mat-form-field flex="80" layout-margin appearance="outline">
			<mat-label i18n>Address 1</mat-label>
			<input matInput formControlName="address1">
			<mat-error *ngFor="let errorMessage of form.controls.address1['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Address 2 -->
		<mat-form-field flex="20" layout-margin appearance="outline">
			<mat-label i18n>Address 2</mat-label>
			<input matInput formControlName="address2">
			<mat-error *ngFor="let errorMessage of form.controls.address2['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>
	<!-- #endregion -->

	<!-- #region Address 3 -->
	<div layout-gt-sm="row" layout="column">
		<mat-form-field flex="80" layout-margin appearance="outline">
			<mat-label i18n>Address 3</mat-label>
			<input matInput formControlName="address3">
			<mat-error *ngFor="let errorMessage of form.controls.address3['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
	</div>
	<!-- #endregion -->

	<!-- #region Postal code & city -->
	<div layout-gt-sm="row" layout="column">
		<!-- #region Postal code-->
		<mat-form-field flex="20" layout-margin appearance="outline">
			<mat-label i18n>Postal code</mat-label>
			<input matInput formControlName="postalCode">
			<mat-error *ngFor="let errorMessage of form.controls.postalCode['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region City-->
		<mat-form-field flex="80" layout-margin appearance="outline">
			<mat-label i18n>City</mat-label>
			<input matInput formControlName="city">
			<mat-error *ngFor="let errorMessage of form.controls.city['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>
	<!-- #endregion -->

	<!-- #region Country -->
	<div layout-gt-sm="row" layout="column" *ngIf="!hideCountry">
		<mat-form-field flex layout-margin appearance="outline">
			<mat-label i18n>Country</mat-label>
			<vd-select formControlName="country" endpoint="country" [params]="{enabled:true}" projection="name,code" key="code"></vd-select>
			<mat-error *ngFor="let errorMessage of form.controls.country['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
	</div>
	<!-- #endregion -->

	<!-- #region Custom fields -->
	<ng-content></ng-content>
	<!-- #endregion -->
</div>