import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Cost } from '../../models/cost';

let nextUniqueId = 0;

@Component({
	selector: 'app-phone-form',
	templateUrl: './phone-form.component.html',
	styleUrls: ['./phone-form.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		useFactory: (controlContainer?: ControlContainer) => controlContainer,
		deps: [[new Optional(), NgForm]]
	}]
})
export class PhoneFormComponent {

	/**
	 * Unique id
	 */
	public id: number = nextUniqueId++;

	/**
	 * The item
	 */
	@Input()
	public item: Cost;

	/**
	 * Hides tax input
	 */
	@Input()
	public required: boolean = true;

	/**
	 * A prefix used for model attribute
	 */
	@Input()
	public modelPrefix: string;

	/**
	 * Sets inputs as disabled
	 */
	@Input()
	public disabled: boolean;

	/**
	 * Sets inputs as readonly
	 */
	@Input()
	public readonly: boolean;

	/**
	 * Hides phone prefix
	 */
	@Input()
	public hidePhonePrefix: boolean;

	/**
	 * Sets header
	 */
	@Input()
	public header: boolean;

	/**
	 * Change callback
	 */
	@Output()
	public change: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Phone prefix validation pattern
	 */
	public phonePrefixPattern: string = '^(0\\d{2,6})$';

	/**
	 * Phone number validation pattern
	 */
	public phoneNumberPattern: string = '^(0\\d{3,30})$';

	/**
	 * Model names
	 */
	@Input() public phonePrefixModelName: string = "phonePrefix";
	@Input() public phoneNumberModelName: string = "phoneNumber";

	/**
	 * Lifecycle hook that is called after data-bound properties 
	 * of a directive are initialized.
	 */
	ngOnInit(): void {
		if (this.modelPrefix) {
			this.phonePrefixModelName = `${this.modelPrefix}PhonePrefix`;
			this.phoneNumberModelName = `${this.modelPrefix}PhoneNumber`;
		}
	}
}