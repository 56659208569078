import { prop, required } from "@messaia/cdk";
import { SftpTransferType } from "../enums/sftp-transfer-type";

export class SftpConfig {
    /**
     * @property
     */
    @prop()
    id: number;

    /**
     * @property
     */
    @required()
    host: string;

    /**
     * @property
     */
    @required()
    port: number = 22;

    /**
     * @property
     */
    @required()
    userName: string;

    /**
     * @property
     */
    @prop()
    password: string;

    /**
     * @property
     */
    @prop()
    passwordClear: string;

    /**
     * @property
     */
    @required()
    remoteDirectory: string;

    /**
     * @property
     */
    @required()
    transferType: SftpTransferType;

    /**
     * @property
     */
    @prop()
    usePrivateKey: boolean;
}