<div layout="column" class="bg-grey" layout-fill flex>
	<div layout="column" layout-fill layout-align="center center">
		<mat-card *ngIf="loaded">
			<mat-card-title>
				<div layout="row" layout-align="center center">
					<mat-icon color="primary">lock</mat-icon>&nbsp;
					<span i18n>Sign In</span>
				</div>
			</mat-card-title>
			<mat-divider></mat-divider>
			<mat-card-content>
				<p i18n>You are not logged in or your session has expired. Please re-login.</p>
			</mat-card-content>
			<mat-divider></mat-divider>
			<mat-card-actions>
				<div layout-margin>
					<button type="button" mat-flat-button color="primary" (click)="login()" i18n>Login</button>
				</div>
			</mat-card-actions>
		</mat-card>
		<div *ngIf="!loaded" layout="column" layout-align="center center">
			<mat-spinner></mat-spinner>
			<div class="md-title pad-top" i18n="@@authentication">Authentication...</div>
		</div>
	</div>
</div>