import { Injectable } from '@angular/core';
import { GenericService } from '@messaia/cdk';
import { Observable } from 'rxjs';
import { AccountData } from '../models/account-data';
import { IbanValidation } from '../models/iban-validation';

@Injectable({ providedIn: 'root' })
export class UtilsService extends GenericService<any> {

	/**
	 * Bill months
	 */
	public months: { name: string, id: number }[] = [];

	/**
	 * Constructor
	 */
	constructor() {
		super('utils');

		this.months = [
			{ name: 'Januar', id: 1 },
			{ name: 'Februar', id: 2 },
			{ name: 'März', id: 3 },
			{ name: 'April', id: 4 },
			{ name: 'Mai', id: 5 },
			{ name: 'Juni', id: 6 },
			{ name: 'Juli', id: 7 },
			{ name: 'August', id: 8 },
			{ name: 'September', id: 9 },
			{ name: 'Oktober', id: 10 },
			{ name: 'November', id: 11 },
			{ name: 'Dezember', id: 12 }
		];
	}

	/**
	 * Validates the IBAN
	 * @param iban 
	 */
	public validateIban(iban: string): Observable<IbanValidation> {
		return this.get('validateIban', { iban: iban });
	}

	/**
	 * Converts accounts data to IBAN
	 * @param accountData 
	 */
	public accountDataToIban(accountData: AccountData): Observable<IbanValidation> {
		return this.get('accountDataToIban', accountData);
	}

	/**
	 * Creates a random number
	 * @param min 
	 * @param max 
	 * @returns 
	 */
	public static randomNumber = (min: number, max: number): number => Math.floor(Math.random() * (max - min) + min);

	/**
	 * Creates a range
	 * @param size 
	 * @param startAt 
	 */
	protected range(start: number, stop: number, step: number): ReadonlyArray<number> {
		return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))
	}
}