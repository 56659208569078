import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@messaia/cdk';
import { CustomerType } from 'projects/Shared/customers-base/src/lib/enums/customer-type';
import { Salutation } from '../../enums/salutation';
import { PersonalData } from '../../models/personal-data';

let nextUniqueId = 0;

@Component({
	selector: 'app-personal-data-form',
	templateUrl: './personal-data-form.component.html',
	styleUrls: ['./personal-data-form.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class PersonalDataFormComponent extends BaseComponent {
	/**
	 * Unique id
	 */
	public id: number = nextUniqueId++;

	/**
	 * Personal data
	 */
	@Input()
	public item: PersonalData;

	/**
	 * The type of salutation enum
	 */
	public Salutation: typeof Salutation = Salutation;

	/**
	 * Disabled?
	 */
	@Input()
	public disabled: boolean;

	/**
	 * Readonly?
	 */
	@Input()
	public readonly: boolean;

	/**
	 * Hides phone prefix
	 */
	@Input()
	public hidePhonePrefix: boolean;

	/**
	 * Shows picture
	 */
	@Input()
	public showPicture: boolean;

	/**
	 * Shows customer number
	 */
	@Input()
	public showCustomerNumber: boolean;

	/**
	 * Links customer number with customer details
	 */
	@Input()
	public linkCustomerNumber: boolean;

	/**
	 * Phone prefix validation pattern
	 */
	@Input()
	public phonePrefixPattern: string = '^(0\\d{2,6})$';

	/**
	 * Phone number validation pattern
	 */
	@Input()
	public phoneNumberPattern: string = '^(\\d{3,30})$';

	/**
	 * Min birthdate date
	 */
	public minBirthdateDate: Date = new Date().addYears(-130);

	/**
	 * Min birthdate date
	 */
	public maxBirthdateDate: Date = new Date().addYears(-18);

	/**
	 * Upload event
	 */
	@Output()
	public uploadPicture: EventEmitter<File> = new EventEmitter<File>();

	/**
	 * change?: event handler
	 */
	@Output()
	public change: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Constructor
	 */
	constructor(
		protected route: ActivatedRoute,
		protected changeDetectorRef: ChangeDetectorRef
	) {
		super(route, changeDetectorRef)
	}

	/**
	 * Handles change events of fields 
	 * @param name The name of the field
	 */
	public onChange(name: string): void {
		this.change.emit(name);
	}

	/**
	 * Navigates to /customers/<type>/<id>
	 */
	public navigateToCustomer(id: number, type: CustomerType) {
		this.navigate([`/customers/${CustomerType[type].toLowerCase()}/edit`, id]);
	}
}