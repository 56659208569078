import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * A guard deciding if a route can be activated.
 */
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

	/**
	 * Constructor
	 * @param oidcSecurityService 
	 * @param router
	 */
	constructor(public oidcSecurityService: OidcSecurityService, private router: Router) { }

	/**
	 * Checks if the route can be activated
	 * @param next 
	 * @param state 
	 */
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
		return this.isLoggedIn();
	}

	/**
	 * Checks if the user is logged in
	 */
	private isLoggedIn(): Observable<boolean | UrlTree> {
		return this.oidcSecurityService.isAuthenticated$.pipe(
			map(({ isAuthenticated }) => {
				/* Allow navigation if authenticated */
				if (isAuthenticated) {
					return true;
				}

				/* Redirect if not authenticated */
				return this.router.parseUrl('/unauthorized');
			})
		);
	}
}