import { Column, Display, Table } from "@messaia/cdk";

@Table({
    endpoint: 'customer/transactions',
    editable: false,
    selectable: false,
    deletable: false
})
export class CustomerTransaction {
    @Column({})
    @Display('Text 1')
    desc1: string;

    @Column({ maxWidth: 40, sticky: true })
    @Display($localize`:@@businessEventId:Business event ID`)
    businessEventId: string;

    @Column({ maxWidth: 70 })
    @Display($localize`:@@voucherNo1:Voucher Nr. 1`)
    voucherNo1: string;

    @Column({ maxWidth: 70 })
    @Display($localize`:@@voucherNo2:Voucher Nr. 2`)
    voucherNo2: string;

    @Column({ maxWidth: 75, shortDate: true })
    @Display($localize`:@@bookingDate:Booking date`)
    bookingDate: Date;

    @Column({ maxWidth: 75, shortDate: true, stickyEnd: true })
    @Display($localize`:@@voucherDate:Voucher date`)
    voucherDate: Date;

    @Column({ maxWidth: 60 })
    @Display($localize`:@@saldo:Saldo`)
    amountIC: number;
    amount: number;

    @Column({ maxWidth: 30 })
    @Display('S/H')
    dcCode: string;

    @Column({ maxWidth: 85 })
    @Display($localize`:@@contraAcc:Contra account`)
    contraAcc: string;

    id: string;
    isGroup0Header: boolean;
    grp0: string;
    isOpened: boolean;
    isContraTrans: boolean;
    amountDebit: number;
    amountCredit: number;
    amountICDebit: number;
    amountICCredit: number;
    amountFCDebit: number;
    amountFCCredit: number;
    linkId: string;
    acTransactionId: string;
    parentId: string;
    oiTransactionId: string;
    journalId: number;
    journalPosition: number;
    isSplit: boolean;
    yearId: number;
    periodId: number;
    transKind: string;
    typeId: string;
    objectId: string;
    clientType: string;
    clientId: string;
    currencyIdHC: string;
    currencyIdFC: string;
    rateFC: number;
    amountFC: number;
    currencyIdIC: string;
    rateIC: number;
    taxId: string;
    taxPerCentage: number;
    amountTax: number;
    amountTaxFC: number;
    amountTaxIC: number;
    businessArea: string;
    desc2: string;
    bankValuta: string;
    quantity: number;
    isoUnit: string;
    quantityAlt: number;
    isoUnitAlt: string;
    processType: string;
    messageFlag: boolean;
    voucherId: string;
    transactionId: string;
    persIdDiv: string;
    interestFlag: boolean;
    interestId: string;
    interestNumber: string;
    clientKind: string;
    purPreTaxId: string;
    purTurnOverTaxId: string;
    purTaxPercentage: number;
    taxBase: number;
    taxBaseIC: number;
    taxInfo: string;
    taxLinkId: string;
    taxCorrectId: string;
    cashFlowCategory: string;
    cashFlowInitiator: string;
    purLedgerNotRepTax: string;
    purTurnOverTaxPercentage: number;
    _CreateUser: string;
    _CreateDate: string;
    _ModifyUser: string;
    _ModifyDate: string;
    _ImportDate: string;
    _ExportDate: string;
    _OrgUnitId: string;
    contraId: string;
    orgUnitDesc: string;
    orgUnitShortDesc: string;
    orgUnitTechId: string;
    voucherNo3: string;
    voucherAssign: number;
    oiCount: number;
    folderId: number;
    cancellationSign: number;
    externalId: string;
    feedbackId: string;
    archiveId: string;
    parentOiId: string;
    feedbackDest: string;
    feedbackMode: number;
    voucherType: string;
    euId: string;
    orgUnitAtSplit: boolean;
    noPayment: boolean;
    contraAccName: string;
    contraAccTypeId: string;
    addAccAUF: string;
    addNameAUF: string;
    addAccHHS: string;
    addNameHHS: string;
    addAccKST: string;
    addNameKST: string;
    addAccKTR: string;
    addNameKTR: string;
    addAccPRJ: string;
    addNamePRJ: string;
    mainAccountId: string;
    accName: string;
    clientName: string;
    institutionId: string;
    costCenterId: string;
    groupId: string;
    carerId: string;
    carerName: string;
    basicAllowance: number;
    availAmount: number;
    isSumPay: boolean;
    isSumPayLine: boolean;
    clientKindValue: string;
    clientCarrierId: string;
    groupObjectId: string;
    groupAccDesc: string;
    lineType: number;
    groupType: number;
    listOrder: number;
    groupDesc: string;
    groupAttributeId: string;
}