import { Directive } from "@angular/core";
import { AbstractControl, NG_ASYNC_VALIDATORS, Validator } from "@angular/forms";
import { UtilsService } from "../services/utils.service";

@Directive({
    selector: '[iban]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: IbanValidatorDirective, multi: true }]
})
export class IbanValidatorDirective implements Validator {

    /**
     * Constructor
     */
    constructor(protected utilService: UtilsService) { }

    /**
     * Method that performs synchronous validation against the provided control.
     * @param control 
     */
    validate(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value || !control.dirty) {
            return new Promise(resolve => resolve(null));
        }

        return new Promise(resolve =>
            this.utilService.validateIban(control.value).subscribe(ibanValidation => {
                let bicCtrl = control.root.get('bic');
                let bankNameCtrl = control.root.get('bankName');

                if (ibanValidation.valid) {
                    /* Set bic */
                    if (bicCtrl) {
                        bicCtrl.setValue(ibanValidation.bic);
                    }

                    /* Set bake name */
                    if (bankNameCtrl) {
                        bankNameCtrl.setValue(ibanValidation.bankName);
                    }

                    resolve(null);
                } else {
                    /* Reset bic */
                    if (bicCtrl) {
                        bicCtrl.setValue('');
                    }

                    /* Reset bake name */
                    if (bankNameCtrl) {
                        bankNameCtrl.setValue('');
                    }

                    resolve({ invalidIban: true });
                }
            }));
    }
}