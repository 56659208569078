<div *ngIf="form" [formGroup]="form" class="container">
	<div layout-gt-sm="row" layout="column">
		<!-- #region Salutation -->
		<mat-form-field flex="40" layout-margin appearance="outline" *ngIf="!options?.salutation">
			<mat-label i18n>Salutation</mat-label>
			<vd-select formControlName="salutation" [enum]="Salutation" [readonly]="readonly" flex></vd-select>
			<mat-error *ngFor="let errorMessage of form.controls.salutation['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Title -->
		<mat-form-field flex="40" layout-margin appearance="outline" *ngIf="!options?.title">
			<mat-label i18n>Title</mat-label>
			<input matInput formControlName="title" [readonly]="readonly">
			<mat-error *ngFor="let errorMessage of form.controls.title['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Customer number -->
		<mat-form-field layout-margin *ngIf="form.controls.number" flex>
			<mat-label i18n>Customer number</mat-label>
			<input matInput formControlName="number" readonly>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<div layout-gt-sm="row" layout="column">
		<!-- #region First name-->
		<mat-form-field flex layout-margin appearance="outline" *ngIf="!options?.firstName">
			<mat-label i18n>First name</mat-label>
			<input matInput formControlName="firstName" [readonly]="readonly">
			<mat-error *ngFor="let errorMessage of form.controls.firstName['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Last name-->
		<mat-form-field flex layout-margin appearance="outline" *ngIf="!options?.lastName">
			<mat-label i18n>Last name</mat-label>
			<input matInput formControlName="lastName" [readonly]="readonly">
			<mat-error *ngFor="let errorMessage of form.controls.lastName['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region birthdate -->
		<mat-form-field flex layout-margin appearance="outline" *ngIf="!options?.hideBirthdate">
			<mat-label i18n>Birthdate</mat-label>
			<input matInput [matDatepicker]="birthdate" formControlName="birthdate" [min]="minBirthdateDate" [max]="maxBirthdateDate" [readonly]="readonly">
			<mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
			<mat-datepicker #birthdate></mat-datepicker>
			<mat-error *ngFor="let errorMessage of form.controls.birthdate['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<div layout-gt-sm="row" layout="column">
		<!-- #region Phone number-->
		<mat-form-field flex layout-margin appearance="outline" *ngIf="!options?.phoneNumber">
			<mat-label i18n>Call number</mat-label>
			<input matInput formControlName="phoneNumber" [onlyNumber]="true" [pattern]="phoneNumberPattern" [readonly]="readonly">
			<mat-error *ngFor="let errorMessage of form.controls.phoneNumber['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->

		<!-- #region Mobile phone number-->
		<mat-form-field flex layout-margin appearance="outline" *ngIf="!options?.mobilePhoneNumber && form.controls?.mobilePhoneNumber">
			<mat-label i18n>Additional call number</mat-label>
			<input matInput formControlName="mobilePhoneNumber" [onlyNumber]="true" [pattern]="phoneNumberPattern" [readonly]="readonly">
			<mat-error *ngFor="let errorMessage of form.controls.mobilePhoneNumber['errorMessages']">{{errorMessage}}</mat-error>
		</mat-form-field>
		<!-- #endregion -->
	</div>

	<!-- #region Custom fields -->
	<ng-content></ng-content>
	<!-- #endregion -->
</div>