import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification } from '@app/customers-base';

@Component({
	selector: 'notification-dialog',
	template: `
		<div mat-dialog-content [innerHtml]="notification?.content"></div>
		<div mat-dialog-actions layout="row">
			<span flex></span>
			<button mat-flat-button (click)="cancel()" color="primary">OK</button>
		</div>
	`,
})
export class NotificationDialogComponent {

	/**
	 * Constructor
	 * @param dialogRef 
	 * @param data 
	 */
	constructor(public dialogRef: MatDialogRef<NotificationDialogComponent>, @Inject(MAT_DIALOG_DATA) public notification: Notification) { }

	/**
	 * Closes the dialog
	 */
	public cancel(): void {
		this.dialogRef.close();
	}
}