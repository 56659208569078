import { email, prop } from "@messaia/cdk";

export class ClientSupport {
    @prop()
    host: string;

    @prop()
    port: number;

    @prop()
    userName: string;

    @prop()
    password: string;

    @prop()
    useSSL: string;

    @prop()
    passwordClear: string;

    @prop()
    @email()
    email: string;

    @prop()
    dueSpan: number = 48;

    @prop()
    autoAssigneeId: number;

    /**
     * constructor
     * @param init 
     */
    public constructor(init?: Partial<ClientSupport>) {
        Object.assign(this, init);
    }
}