// Ensure this is treated as a module.
export { };

declare global {
	interface String {
		toCamelCase(): string;
		capitalize(): string;
		hashCode(): string;
	}

	interface Array<T> {
		remove(elem: T): Array<T>;
	}

	interface Date {
		addHours(hours: number): Date;
	}

	interface Date {
		addDays(days: number): Date;
	}

	interface Date {
		addYears(years: number): Date;
	}

	interface Number {
		round(precision?: number): number;
	}
}

/**
 * Converts this string to camel case
 */
String.prototype.toCamelCase = function (): string {
	return this.replace(/(?:^\w|[A-Z]|-|\b\w)/g, (ltr: string, idx: number) => idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()).replace(/\s+|-/g, '');
};

/**
 * Converts the first character of this string to upper case
 */
String.prototype.capitalize = function () {
	return this.charAt(0).toUpperCase() + this.slice(1);
}

/**
 * Computes the hash code of this string
 */
String.prototype.hashCode = function (): string {
	var hash = 0;
	if (this.length !== 0) {
		for (var i = 0; i < this.length; i++) {
			var char = this.charCodeAt(i);
			hash = ((hash << 5) - hash) + char;
			hash = hash & hash;
		}
	}

	return hash.toString();
};

/**
 * Removes an element from an array.
 */
if (!Array.prototype.remove) {
	Array.prototype.remove = function <T>(elem: T): T[] {
		var idx = this.indexOf(elem);
		if (idx != -1) {
			return this.splice(idx, 1);
		}

		return this;
	}
}

/**
 * Adds hours to a date object
 */
if (!Date.prototype.addHours) {
	Date.prototype.addHours = function (h: number): Date {
		this.setTime(this.getTime() + (h * 60 * 60 * 1000));
		return this;
	}
}

/**
 * Adds days to a date object
 */
if (!Date.prototype.addDays) {
	Date.prototype.addDays = function (d: number): Date {
		this.setDate(this.getDate() + d);
		return this;
	}
}

/**
 * Adds days to a date object
 */
if (!Date.prototype.addYears) {
	Date.prototype.addYears = function (y: number): Date {
		this.setFullYear(this.getFullYear() + y);
		return this;
	}
}

/**
 * Adds hours to a date object
 */
if (!Date.prototype.addHours) {
	Date.prototype.addHours = function (h: number): Date {
		this.setTime(this.getTime() + (h * 60 * 60 * 1000));
		return this;
	}
}

/**
 * Rounds a number
 */
if (!Number.prototype.round) {
	Number.prototype.round = function (precision?: number): number {
		return parseFloat(this.toFixed(precision > 0 ? precision : 4));
	}
}