<div layout="column" class="bg-grey" layout-fill flex>
	<div layout="column" layout-fill layout-align="center center">
		<mat-card>
			<mat-card-title>
				<div layout="row" layout-align="center center">
					<mat-icon color="warn">warning</mat-icon>&nbsp;
					<span i18n>Access denied</span>
				</div>
			</mat-card-title>
			<mat-divider></mat-divider>
			<mat-card-content>
				<p i18n>The resource is not accessible. You might not have permission to use this resource. Access is denied.</p>
				<p i18n>Contact the administrator to find out if you have access permissions.</p>
				<p *ngIf="user?.email"><span i18n>You are logged in as</span>: <span class="txt-bold">{{user?.email}}</span></p>
			</mat-card-content>
			<mat-divider></mat-divider>
			<mat-card-actions>
				<div layout-margin>
					<button type="button" mat-flat-button color="primary" (click)="logout()" i18n>Logout</button>
				</div>
			</mat-card-actions>
		</mat-card>
	</div>
</div>