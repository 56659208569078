export enum PriceUnit {
    Piece,
    Second,
    Minute,
    Hour,
    Day,
    Month,
    Quarter,
    HalfYear,
    Year,
    KByte,
    MByte,
    GByte,
    TByte
}