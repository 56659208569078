import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-audit',
	templateUrl: './audit.component.html',
	styleUrls: ['./audit.component.scss']
})
export class AuditComponent {

	/**
	 * Audit data
	 */
	@Input()
	public item: any;
}