import { Directive } from "@angular/core";
import { AbstractControl, NG_ASYNC_VALIDATORS, Validator } from "@angular/forms";

@Directive({
    selector: '[ngModel],[formControlName],[formControl]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: EmptyStringResetDirective, multi: true }]
})
export class EmptyStringResetDirective implements Validator {

    /**
     * Method that performs synchronous validation against the provided control.
     * @param control 
     */
    validate(control: AbstractControl): { [key: string]: any } | null {
        if (typeof control?.value === 'string' && control?.value == "") {
            control.setValue(null);
        }

        return new Promise(resolve => resolve(null));
    }
}