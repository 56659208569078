import { required } from "@messaia/cdk";
import { PaymentCycle } from "../enums/payment-cycle";

export class Cost {

	@required()
	name: string;

	paymentCycle: PaymentCycle = PaymentCycle.Monthly;

	@required()
	net: number = 0;

	@required()
	gross: number = 0;

	@required()
	tax: number = 19;

	@required()
	taxAmount: number = 0;

	@required()
	minimumTerm: number = 0;
	timeLimit: number = 0;
}