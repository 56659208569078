<vd-layout #layout>
	<vd-navigation-drawer logo="assets:appIcon" navigationRoute="/" color="primary" flex></vd-navigation-drawer>
	<vd-layout-nav logo="assets:appIcon" navigationRoute="/">
		<div vd-toolbar-content layout="row" layout-align="center center" flex>
			<span flex></span>
		</div>
		<div layout="column" class="md-content" flex>
			<div layout="column" layout-fill layout-align="start center">
				<mat-card>
					<mat-card-content>
						<div class="md-headline">Sie sind jetzt ausgeloggt!</div>
					</mat-card-content>
					<mat-divider></mat-divider>
					<!--<mat-card-actions>
						<div layout-margin>
							<button mat-flat-button color="primary" (click)="securityService.login()">Anmelden</button>
						</div>
					</mat-card-actions>-->
				</mat-card>
			</div>
		</div>
	</vd-layout-nav>
</vd-layout>