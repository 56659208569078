<!-- #region Title -->
<div *ngIf="header" class="price-title mat-subheading-2" layout-margin>{{header}}</div>
<!-- #endregion-->

<div layout="column" layout-gt-sm="row" *ngIf="item" flex>
	<!-- #region Phone number prefix -->
	<mat-form-field flex="20" layout-margin *ngIf="!hidePhonePrefix">
		<mat-label i18n>Phone number prefix</mat-label>
		<input matInput name="phonePrefix{{id}}" [pattern]="phonePrefixPattern" [onlyNumber]="true" [(ngModel)]="item[phonePrefixModelName]" #phonePrefix="ngModel" [required]="required" [disabled]="disabled" [readonly]="readonly" placeholder="Phone number prefix" i18n-placeholder>
		<mat-error *ngIf="phonePrefix.errors?.required"><span i18n>Phone number prefix</span><span i18n> is required</span></mat-error>
	</mat-form-field>
	<!-- #endregion -->

	<!-- #region Phone number -->
	<mat-form-field flex="80" layout-margin>
		<mat-label i18n>Phone number</mat-label>
		<input matInput name="phoneNumber{{id}}" [onlyNumber]="true" minlength="2" maxlength="30" [(ngModel)]="item[phoneNumberModelName]" #phoneNumber="ngModel" [required]="required" [disabled]="disabled" [readonly]="readonly" placeholder="Phone number" i18n-placeholder>
		<mat-error *ngIf="phoneNumber.errors?.required"><span i18n>Phone number</span><span i18n> is required</span></mat-error>
		<mat-error *ngIf="phoneNumber.errors?.minlength"><span i18n>Phone number</span><span i18n="@@validation.minlength"> must be at least {{phoneNumber.errors?.minlength?.requiredLength}} characters long</span></mat-error>
	</mat-form-field>
	<!-- #endregion -->

	<!-- #region Custom fields -->
	<ng-content></ng-content>
	<!-- #endregion -->
</div>